import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { User } from "../models/user.model";
import { IQuery } from "../models/query.interface";
import { Tenant } from "../models/tenant.model";

export interface LoginResponse {
    token: string;
    user: User;
    tenant: Tenant;
}

export interface UserQuery extends IQuery {
    branchId?: string;
}

@Injectable({
    providedIn: "root",
})
export class AuthService {
    constructor(protected http: HttpClient) {}

    login(options: {
        username: string;
        password: string;
    }): Observable<LoginResponse> {
        const request = {
            username: options.username,
            password: options.password,
        };

        return this.http.post("neonis.api.auth.v1/login", request).pipe(
            map((response: any) => ({
                token: response.token,
                user: new User(response.user),
                tenant: new Tenant(response.tenant),
            }))
        );
    }

    refreshToken(token: string): Observable<LoginResponse> {
        return this.http.get("neonis.api.auth.v1/refresh/token/" + token).pipe(
            map((response: any) => ({
                token: response.token,
                user: new User(response.user),
                tenant: new Tenant(response.user),
            }))
        );
    }
}
