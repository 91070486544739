import { Storable } from "./storable.model";
import { TyreInfo } from "./tyre-info.model";

function getMinValue(...values) {
    let minValue = null;

    for (let value of values) {
        if (value !== null && value !== undefined) {
            if (minValue === null || value < minValue) {
                minValue = value;
            }
        }
    }

    return minValue;
}

export class StoredTyre extends Storable {
    age: number;
    info = new TyreInfo();

    profileDepth: number;
    profileDepthInner: number;
    profileDepthOuter: number;
    profileDepthMiddle: number;

    constructor(tyre?: Partial<StoredTyre>) {
        super(tyre);

        if (tyre) {
            Object.assign(this, tyre);
            this.info = new TyreInfo(tyre.info);

            this.profileDepth = getMinValue(
                tyre.profileDepth,
                tyre.profileDepthInner,
                tyre.profileDepthOuter,
                tyre.profileDepthMiddle
            );
        }

        // Default values
        this.info = this.info || new TyreInfo();
        this.state = this.state || "UNKNOWN";
    }
}
