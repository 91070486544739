import { NgRedux } from "@angular-redux/store";
import { Observable, Subject, of, EMPTY } from "rxjs";
import { getBaseStore } from "./helper";

export const ACTION_START = "ACTION_START";
export const ACTION_FINISH = "ACTION_FINISH";
export const ACTION_ERROR = "ACTION_ERROR";

export interface ActionOptions {
    type?: string;
    single?: boolean;
    dispatch?: boolean;
    generate?: boolean;
    payload?: "args" | "result";
    return?: "action" | "payload";
    cache?: {
        expires?: boolean;
        maxAge?: number;
        return?: string;
    };
}

const DEFAULT_ACTION_OPTIONS: ActionOptions = {
    type: null,
    single: true,
    dispatch: true,
    generate: true,
    payload: "result",
    return: "payload",
    cache: null,
};

export function Action(
    actionType?: string,
    options?: ActionOptions
): PropertyDecorator {
    return function decorate(
        target: Object,
        key: string | symbol | number,
        descriptor?: PropertyDescriptor
    ): PropertyDescriptor {
        options = Object.assign({}, DEFAULT_ACTION_OPTIONS, options || {});

        let originalMethod: Function;

        // Caching properties
        let cachedValue: any;
        let lastCall: Date;

        let isPromise = false;
        let isObservable = false;

        const wrapped = function (this: any, ...args: any[]) {
            const store = getBaseStore(this) || NgRedux.instance;

            if (options.cache && lastCall != null) {
                const cacheExpired =
                    (options.cache.expires == null || options.cache.expires) &&
                    new Date().getTime() - lastCall.getTime() >
                        options.cache.maxAge;

                if (!cacheExpired) {
                    const returnValue =
                        options.cache.return != null
                            ? store.getState()[options.cache.return]
                            : cachedValue;

                    if (isPromise) {
                        return Promise.resolve(returnValue);
                    }

                    if (isObservable) {
                        return returnValue != null ? of(returnValue) : EMPTY;
                    }

                    return returnValue;
                }
            }

            lastCall = new Date();

            if (!actionType) {
                actionType = (
                    target.constructor.name +
                    "_" +
                    key.toString()
                ).toUpperCase();
            }

            const dispatchActionEvent = (type: string, error?: any) => {
                if (store) {
                    store.dispatch({
                        type: type,
                        payload: error
                            ? {
                                  action: actionType,
                                  error: error,
                              }
                            : actionType,
                    });
                }
            };

            const dispatchAction = (result: any) => {
                let action = result;

                if (options.generate && actionType) {
                    action = {
                        type: actionType,
                        payload: null,
                    };

                    if (options.payload === "args") {
                        action.payload = args.length > 0 ? args[0] : null;
                    } else if (options.payload === "result") {
                        action.payload = result;
                    }
                }

                if (options.dispatch && action) {
                    // Convert to plain object if required.
                    const proto = Object.getPrototypeOf(action);
                    if (proto !== Object.prototype) {
                        action = Object.assign({}, action);
                    }

                    if (store) {
                        store.dispatch(action);
                    }
                }

                dispatchActionEvent(ACTION_FINISH);

                const returnValue =
                    options.return === "action" ? action : action.payload;

                if (options.cache && options.cache.return == null) {
                    cachedValue = returnValue;
                }

                return returnValue;
            };

            dispatchActionEvent(ACTION_START);

            const response = originalMethod.apply(this, args);

            isPromise = typeof response.then === "function";
            if (isPromise) {
                return new Promise((resolve, reject) => {
                    response.then(
                        (action: any) => {
                            resolve(dispatchAction(action));
                        },
                        (error: any) => {
                            dispatchActionEvent(ACTION_ERROR, error);
                            reject(error);
                        }
                    );
                });
            }

            isObservable = response instanceof Observable;
            if (isObservable) {
                const subject = new Subject();
                const subscription = response.subscribe(
                    (action: any) => {
                        subject.next(dispatchAction(action));

                        if (options.single) {
                            subject.complete();
                            subscription.unsubscribe();
                        }
                    },
                    (error: Error) => {
                        subject.error(error);
                        dispatchActionEvent(ACTION_ERROR, error.message);
                    },
                    () => {
                        subject.complete();
                        subscription.unsubscribe();
                    }
                );

                return subject.asObservable();
            }

            return dispatchAction(response);
        };

        descriptor = descriptor || Object.getOwnPropertyDescriptor(target, key);
        if (descriptor === undefined) {
            const dispatchDescriptor: PropertyDescriptor = {
                get: () => wrapped,
                set: (setMethod) => (originalMethod = setMethod),
            };

            Object.defineProperty(target, key, dispatchDescriptor);
            return dispatchDescriptor;
        } else {
            originalMethod = descriptor.value;
            descriptor.value = wrapped;
            return descriptor;
        }
    };
}
