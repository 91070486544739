import { join, isEmpty, equals } from "@tcn/ngx-common";

export enum AddressType {
    PRIVAT,
    BUSINESS,
    BILLING,
    SHIPPING
}

export enum GeoQuality {
    UNKNOWN,
    APPROX,
    GOOD,
    EXACT
}

export class Address {
    state: string;
    name: string;
    type: AddressType;
    geoLatitude: number;
    geoLongitude: number;
    nameAddition2: string;
    nameAddition1: string;
    nameAddition3: string;
    countryCode: string;
    houseNumber: string;
    countryName: string;
    county: string;
    geoQuality: GeoQuality;
    note1: string;
    note2: string;
    street: string;
    city: string;
    zipcode: string;
    note3: string;

    toString(options: { withoutName?: boolean } = {}): string {
        const streetWithNumber = join([this.street, this.houseNumber]) || null;
        return join(
            [
                options.withoutName ? null : this.name,
                [streetWithNumber, "?:, "],
                this.zipcode,
                this.city
            ],
            " ",
            false
        );
    }

    equals(other: Address) {
        return (
            equals(this.name, other.name) &&
            equals(this.street, other.street) &&
            equals(this.city, other.city) &&
            equals(this.zipcode, other.zipcode) &&
            equals(this.countryCode, other.countryCode) &&
            equals(this.name, other.name) &&
            equals(this.countryCode, other.countryCode)
        );
    }

    get isEmpty(): boolean {
        return (
            isEmpty(this.name) &&
            isEmpty(this.street) &&
            isEmpty(this.city) &&
            isEmpty(this.zipcode) &&
            isEmpty(this.countryCode) &&
            isEmpty(this.countryName) &&
            isEmpty(this.name)
        );
    }

    constructor(address?: Partial<Address>) {
        if (address) {
            Object.assign(this, address);
        }
    }
}
