import { Injectable } from "@angular/core";
import { ActionSheetController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { ActionSheetOptions } from "@ionic/core";

@Injectable()
export class LocalizedActionSheetController {
    constructor(
        private actionSheetCtrl: ActionSheetController,
        private translate: TranslateService
    ) {}

    create(options?: ActionSheetOptions): Promise<any> {
        let requiredTranslations: Array<any> = [];

        if (options) {
            if (options.header) {
                requiredTranslations.push(options.header);
            }

            if (options.subHeader) {
                requiredTranslations.push(options.subHeader);
            }

            if (options.buttons) {
                for (let i = 0; i < options.buttons.length; ++i) {
                    let button: any = options.buttons[i];
                    if (button.text) {
                        requiredTranslations.push(button.text);
                    }
                }
            }
        }

        return new Promise((resolve, reject) => {
            this.translate.get(requiredTranslations).subscribe(
                translations => {
                    if (options) {
                        options = Object.assign({}, options);

                        if (options.header) {
                            options.header = translations[options.header];
                        }

                        if (options.subHeader) {
                            options.subHeader = translations[options.subHeader];
                        }

                        if (options.buttons) {
                            for (let i = 0; i < options.buttons.length; ++i) {
                                let button: any = options.buttons[i];
                                button.text = translations[button.text];
                            }
                        }
                    }

                    resolve(this.actionSheetCtrl.create(options));
                },
                error => {
                    reject(error);
                }
            );
        });
    }
}
