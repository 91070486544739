import { join } from "@tcn/ngx-common";

export class RimInfo {
    createdAt: Date;
    updatedAt: Date;

    id: string;
    manufacturer: string;
    ean: string;
    manufacturerId: string;
    group: string;
    description: string;
    color: string;
    width: string;
    diameter: string;
    numberOfHoles: number;
    holeCircle1: number;
    holeCircle2: number;
    offset: number;
    drilling: number;
    design: string;
    fixBasis: string;
    note: string;
    weight: number;
    winterFit: boolean;
    hump: string;
    execution: string;
    salesPrice: number;

    constructor(rim?: Partial<RimInfo>) {
        if (rim) {
            Object.assign(this, rim);

            this.createdAt = rim.createdAt ? new Date(rim.createdAt) : null;
            this.updatedAt = rim.updatedAt ? new Date(rim.updatedAt) : null;
        }
    }

    get label(): string {
        return join([
            this.manufacturer,
            this.design,
            [this.width, "?:x", this.diameter],
            [this.width, "?:x", this.diameter],
            [this.numberOfHoles, "?:x", this.diameter],
            ["ET:?", this.offset],
            ["MB:?", this.drilling],
        ]);
    }
}
