import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { QueryResponse } from "../../core/models/query-response.model";
import { Query } from "../../core/models/query.model";
import { Tag } from "../models/tag.model";

export class TagsQuery extends Query {
    identity: string;
    target: string;

    constructor(query?: Partial<TagsQuery>) {
        super(query);

        if (query) {
            Object.assign(this, query);
        }

        this.sortBy = this.sortBy || "name";
        this.sortType = this.sortType || "asc";
    }

    get empty(): boolean {
        const empty = !this.target;
        return empty;
    }
}

@Injectable({ providedIn: "root" })
export class TagsService {
    constructor(protected http: HttpClient) {}

    queryTags(query: TagsQuery): Observable<QueryResponse<Tag>> {
        return this.http.post("neonis.api.tags.v1/query", query).pipe(
            map(
                (response: any) =>
                    new QueryResponse<Tag>({
                        ...response,
                        data: response.data.map(tag => new Tag(tag))
                    })
            )
        );
    }

    getTag(tagId: string): Observable<Tag> {
        return this.http
            .get(`neonis.api.tags.v1/${tagId}`)
            .pipe(map(tag => new Tag(tag)));
    }

    saveTag(tag: Tag): Observable<Tag> {
        const save$ =
            tag.id != null
                ? this.http.post(`neonis.api.tags.v1/${tag.id}`, tag)
                : this.http.put("neonis.api.tags.v1", tag);

        return save$.pipe(map(response => new Tag(response)));
    }

    deletTag(tagId: string): Observable<any> {
        return this.http.delete(`neonis.api.tags.v1/${tagId}`);
    }
}
