import { RimInfo } from "./rim-info.model";
import { Storable } from "./storable.model";

export class StoredRim extends Storable {
    info: RimInfo;

    constructor(rim?: Partial<StoredRim>) {
        super(rim);

        if (rim) {
            Object.assign(this, rim);
            this.info = new RimInfo(rim.info);
        }

        // Default values
        this.info = this.info || new RimInfo();
        this.state = this.state || "UNKNOWN";
    }
}
