import { IQuery } from "./query.interface";

export class Query implements IQuery {
    first = 0;
    max = 25;
    sortBy: string;
    sortType: "asc" | "desc";

    constructor(query?: Partial<Query>) {
        if (query) {
            Object.assign(this, query);
        }
    }

    toggleSortType() {
        this.sortType = this.sortType === "asc" ? "desc" : "asc";
    }

    equals(other: Partial<Query>): boolean {
        return JSON.stringify(this) === JSON.stringify(other);
    }
}
