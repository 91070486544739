import { IQuery } from "./query.interface";

export class Query implements IQuery {
    first = 0;
    max = 25;
    sortBy: string;
    sortType: string;

    constructor(query?: Partial<Query>, mergeWith?: Partial<Query>) {
        this.assign(query);
        this.assign(mergeWith);
    }

    protected assign(query) {
        if (query) {
            Object.assign(this, query);
        }
    }
}
