import { Entity } from "../../core/models/entity.model";

export class VoucherItem extends Entity {
    refId: string;
    source: string;
    deliveryDate: Date;
    name: string;
    note: string;
    type: string;
    currencyCode = "EUR";
    currencySign = "€";

    private _netPrice: number;
    private _grossPrice: number;
    private _vatRate: number;
    vatAmount: number;

    private _quantity: number;
    totalNetPrice: number;
    totalGrossPrice: number;
    totalVatAmount: number;

    ean: string;
    articleId: string;
    sellersId: string;
    distributorsId: string;
    manufacturerId: string;
    dataProviderId: string;
    manufacturer: string;

    productGroup: string;
    productGroup2: string;
    productGroup3: string;
    productGroup4: string;
    productGroup5: string;
    productGroup6: string;
    productGroup7: string;
    productGroup9: string;
    productGroup10: string;

    constructor(item?: Partial<VoucherItem>) {
        super(item);

        if (item) {
            this._netPrice = item.netPrice;
            this._grossPrice = item.grossPrice;
            this._vatRate = item.vatRate;
            this._quantity = item.quantity;
        }
    }

    get netPrice(): number {
        return this._netPrice;
    }

    set netPrice(netPrice: number) {
        this._netPrice = netPrice;

        if (netPrice != null && this._vatRate != null) {
            this._grossPrice =
                Math.round(netPrice * ((100 + this._vatRate) / 100) * 100) /
                100;

            this.vatAmount =
                Math.round((this._grossPrice - this._netPrice) * 100) / 100;

            this.calculateTotals();
        } else {
            this._grossPrice = null;
            this.vatAmount = null;
            this.totalNetPrice = null;
            this.totalGrossPrice = null;
            this.totalVatAmount = null;
        }
    }

    get grossPrice(): number {
        return this._grossPrice;
    }

    set grossPrice(grossPrice: number) {
        this._grossPrice = grossPrice;

        if (grossPrice != null && this._vatRate != null) {
            this._netPrice =
                Math.round((grossPrice / ((100 + this._vatRate) / 100)) * 100) /
                100;

            this.vatAmount =
                Math.round((this._grossPrice - this._netPrice) * 100) / 100;

            this.calculateTotals();
        } else {
            this.netPrice = null;
            this.vatAmount = null;

            this.totalNetPrice = null;
            this.totalGrossPrice = null;
            this.totalVatAmount = null;
        }
    }

    get vatRate(): number {
        return this._vatRate;
    }

    set vatRate(vatRate: number) {
        if (this._netPrice != null) {
            this._grossPrice =
                Math.round(
                    this._netPrice * ((100 + this.vatRate) / 100) * 100
                ) / 100;
        } else if (this.grossPrice != null) {
            this._netPrice =
                Math.round(
                    (this._grossPrice / ((100 + this.vatRate) / 100)) * 100
                ) / 100;
        }

        this._vatRate = vatRate != null ? vatRate : 19;

        if (this._netPrice != null) {
            this._grossPrice =
                Math.round(
                    this._netPrice * ((100 + this.vatRate) / 100) * 100
                ) / 100;

            this.vatAmount =
                Math.round((this.grossPrice - this.netPrice) * 100) / 100;

            this.calculateTotals();
        } else {
            this._netPrice = null;
            this._grossPrice = null;
            this.vatAmount = null;
            this.totalNetPrice = null;
            this.totalGrossPrice = null;
            this.totalVatAmount = null;
        }
    }

    get quantity(): number {
        return this._quantity;
    }

    set quantity(quantity: number) {
        this._quantity = quantity;
        this.calculateTotals();
    }

    calculate() {
        this.calculateTotals();
    }

    private calculateTotals() {
        if (this._quantity != null) {
            this.totalNetPrice = this._netPrice * this._quantity;
            this.totalGrossPrice = this._grossPrice * this._quantity;
            this.totalVatAmount = this.vatAmount * this._quantity;
        } else {
            this.totalNetPrice = this._netPrice;
            this.totalGrossPrice = this._grossPrice;
            this.totalVatAmount = this.vatAmount;
        }
    }
}
