import { Address } from "./address.model";
import { ContactInfo } from "./contact-info.model";
import { Entity } from "./entity.model";

export class Branch extends Entity {
    number: string;
    name: string;
    onlineName: string;
    tiein: string;
    image: string;
    language: string;
    address: Address;
    contactInfo: ContactInfo;
    weekOpeningHoursFrom: Date;
    weekOpeningHoursTo: Date;
    weekendOpeningHoursFrom: Date;
    weekendOpeningHoursTo: Date;

    constructor(branch?: Partial<Branch>) {
        super(branch);

        if (branch) {
            Object.assign(this, branch);

            this.weekOpeningHoursFrom = branch.weekOpeningHoursFrom
                ? new Date(branch.weekOpeningHoursFrom)
                : null;

            this.weekOpeningHoursTo = branch.weekOpeningHoursTo
                ? new Date(branch.weekOpeningHoursTo)
                : null;

            this.weekendOpeningHoursFrom = branch.weekendOpeningHoursFrom
                ? new Date(branch.weekendOpeningHoursFrom)
                : null;

            this.weekendOpeningHoursTo = branch.weekendOpeningHoursTo
                ? new Date(branch.weekendOpeningHoursTo)
                : null;

            this.address = new Address(branch.address);
            this.contactInfo = new ContactInfo(branch.contactInfo);
        }

        // Default values.
        this.address = this.address || new Address();
        this.contactInfo = this.contactInfo || new ContactInfo();
    }
}
