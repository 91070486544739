import { Entity } from "./entity.model";

export class Permission extends Entity {
    recipient: string;
    target: string;
    action;
    discriminator: string;

    constructor(permission?: Partial<Permission>) {
        super(permission);

        if (permission) {
            Object.assign(this, permission);
        }
    }
}
