export class WorkState {
    id: string;
    refId: string;
    name: string;
    icon: string;
    color: string;

    constructor(state?: Partial<WorkState>) {
        if (state) {
            Object.assign(this, state);
        }
    }
}
