import * as _ from "lodash";

import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    Output,
    OnInit
} from "@angular/core";

import { Items } from "@tcn/ngx-common";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, takeWhile } from "rxjs/operators";

export interface ItemsSearchSource {
    items: Items;
    field: string;
    fields: Array<string>;
}

@Component({
    selector: "items-search",
    templateUrl: "items-search.html"
})
export class ItemsSearchComponent implements OnInit, OnDestroy {
    @Input()
    animated = false;

    @Input()
    sources: Array<ItemsSearchSource> = [];

    @Input()
    debounceTime = 500;

    @Output()
    cleared = new EventEmitter<void>();

    searchTerm: string;
    searchTerm$ = new Subject<String>();

    constructor() {}

    ngOnInit() {
        this.watchSearchTerm();
    }

    ngOnDestroy(): void {
        this.searchTerm$.complete();
    }

    clear() {
        this.cleared.emit();
    }

    onChange() {
        this.searchTerm$.next(this.searchTerm);
    }

    private watchSearchTerm() {
        this.searchTerm$
            .pipe(debounceTime(this.debounceTime), distinctUntilChanged())
            .subscribe(query => {
                if (query) {
                    query = query.trim().toLowerCase();

                    if (query !== "" && query.length >= 3) {
                        this.sources.forEach(source => source.items.hideAll());

                        this.sources.forEach(source =>
                            source.items.all.forEach(item => {
                                for (let i = 0; i < source.fields.length; ++i) {
                                    const field = source.fields[i];
                                    let fieldValue = _.get(item.value, field);

                                    if (fieldValue) {
                                        fieldValue = fieldValue.toLowerCase();
                                        item.hidden =
                                            fieldValue.indexOf(query) === -1;
                                    }

                                    if (!item._hidden) {
                                        break;
                                    }
                                }
                            })
                        );
                    }
                } else {
                    this.sources.forEach(source => source.items.showAll());
                }
            });
    }
}
