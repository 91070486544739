import { addMinutes, differenceInMinutes, isAfter, isBefore } from "date-fns";
import * as moment from "moment";

import { Entity } from "../../core/models/entity.model";
import { Resource } from "../../core/models/resource.model";
import { Service } from "../../core/models/service.model";
import { User } from "../../core/models/user.model";
import { Customer } from "../../customers/models/customer.model";
import { Vehicle } from "../../vehicles/models/vehicle.model";
import { WheelStorage } from "../../wheels/models/wheelstorage.model";
import { Branch } from "./../../core/models/branch.model";
import { DataField } from "./../../core/models/datafield.model";
import { InsuranceCase } from "./insurancecase.model";
import { WorkState } from "./workstate.model";

export type BookingProgress =
    | "WAITING"
    | "DELIVERED"
    | "PROCESSING"
    | "FINISHED"
    | "PICKEDUP";

export type BookingState = "open" | "finished" | "cancelled" | "proposed" | "rejected";

export interface BookingColor {
    text: string;
    background: string;
}

export class Booking extends Entity {
    refId: string;
    refId2: string;
    refId3: string;

    syncedOn: Date;
    creationDate: Date;
    updatedOn: Date;
    creator: string;

    source: string;
    startDate: Date;
    endDate: Date;
    title: string;
    progress: BookingProgress;
    workStates: WorkState[];
    location: string;
    message: string;
    messageAsHTML: string;
    state: BookingState;
    resource: Resource;
    service: Service;
    additionalServices: Service[];
    description: string;
    descriptionAsHTML: string;
    color: BookingColor;
    processor: string;
    traced: boolean;
    fullDay: boolean;
    scheduled: boolean;
    scheduledDuration: number;
    carPickup: Date;
    carDelivery: Date;

    serviceBookletReceived: boolean;
    vehicleKeysReceived: number;
    oldPartsDisposal: boolean;
    vehicleRegistrationReceived: boolean;

    orderNumber: string;
    orderRefId: string;
    voucherNo: string;
    voucherType: string;
    voucherRefId: string;
    quoteNumber: string;
    quoteRefId: string;
    wmbasketUUID: string;
    openTodosCount: number;
    requiredDuration: number;

    vehiclePartsReminderEnabled: boolean;
    vehiclePartsReminderDays: number;
    vehiclePartsOrdered: boolean;

    groupId: string;
    seriesId: string;

    notificationText: string;
    confirmationEmail: string;
    remindCustomerByEmail: boolean;
    remindCustomerBySMS: boolean;
    remindUserBySMS: boolean;
    remindUserByEmail: boolean;
    reminderCustomerEmailSend: boolean;
    reminderCustomerSMSSend: boolean;
    reminderDriverSMSSend: boolean;
    reminderDriverEmailSend: boolean;
    reminderUserSMSSend: boolean;
    reminderUserEmailSend: boolean;

    insuranceCase: InsuranceCase;
    dataFields: DataField[];
    branch: Branch;
    user: User;
    customer: Customer;
    vehicle: Vehicle;
    storage: WheelStorage;

    constructor(booking?: Partial<Booking>) {
        super(booking);

        if (booking) {
            Object.assign(this, booking);

            this.syncedOn = booking.syncedOn
                ? new Date(booking.syncedOn)
                : null;

            this.creationDate = booking.creationDate
                ? new Date(booking.creationDate)
                : null;

            this.updatedOn = booking.updatedOn
                ? new Date(booking.updatedOn)
                : null;

            this.resource = booking.resource
                ? new Resource(booking.resource)
                : null;

            this.service = booking.service
                ? new Service(booking.service)
                : null;

            this.additionalServices = booking.additionalServices
                ? booking.additionalServices.map(
                      service => new Service(service)
                  )
                : [];

            this.dataFields = booking.dataFields
                ? booking.dataFields.map(field => new DataField(field))
                : [];

            this.insuranceCase = booking.insuranceCase
                ? new InsuranceCase(booking.insuranceCase)
                : null;

            this.user = booking.user ? new User(booking.user) : null;
            this.branch = booking.branch ? new Branch(booking.branch) : null;

            this.customer = booking.customer
                ? new Customer(booking.customer)
                : null;

            this.vehicle = booking.vehicle
                ? new Vehicle(booking.vehicle)
                : null;

            this.storage = booking.storage
                ? new WheelStorage(booking.storage)
                : null;
        }

        // Default values.
        this.workStates = this.workStates || [];
        this.additionalServices = this.additionalServices || [];
        this.dataFields = this.dataFields || [];

        this.color = this.color || {
            text: null,
            background: null
        };
    }

    get open() {
        return this.state === "open" && isAfter(this.endDate, new Date());
    }

    get finished() {
        return this.state !== "open" || isBefore(this.endDate, new Date());
    }

    get cancelled() {
        return this.state === "cancelled" || this.state === "rejected";
    }

    get duration() {
        let duration;

        // TODO: Refactor duration format to pipe or component

        const diff = differenceInMinutes(this.endDate, this.startDate);
        if (diff <= 60) {
            duration = diff + " Min";
        } else {
            if (diff % 60 === 0) {
                duration = moment.duration(diff, "minutes"); // .format('h [Std]');
            } else {
                duration = moment.duration(diff, "minutes"); // .format('h:mm [Std]');
            }
        }

        return duration;
    }

    get allServices(): Service[] {
        const services = [];
        if (this.service) {
            services.push(this.service);
        }

        if (this.additionalServices) {
            services.push(...this.additionalServices);
        }

        return services;
    }

    isActive(interval: number): boolean {
        return this.isBetween(
            new Date(),
            addMinutes(new Date(), interval || 5)
        );
    }

    isBetween(startDate: Date, endDate: Date): boolean {
        return (
            isBefore(this.startDate, endDate) &&
            isAfter(this.endDate, startDate)
        );
    }
}
