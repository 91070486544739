import { NgForOf, NgForOfContext } from "@angular/common";
import {
    Directive,
    Input,
    IterableDiffers,
    TemplateRef,
    ViewContainerRef
} from "@angular/core";

function range(start, stop, step = 1) {
    var a = [start],
        b = start;
    while (b < stop) {
        a.push((b += step || 1));
    }
    return a;
}

@Directive({
    selector: "[ngFor][ngForOfRange]"
})
export class NgForRangeDirective<T> extends NgForOf<T> {
    @Input()
    set ngForOfRange(def: number[]) {
        this.range = range(def[0], def[1], def.length === 3 ? def[2] : 1);
        const self: any = this;
        self.ngForOf = this.range;
        super.ngDoCheck();
    }

    private range: number[] = [];

    constructor(
        viewContainer: ViewContainerRef,
        template: TemplateRef<NgForOfContext<T>>,
        differs: IterableDiffers
    ) {
        super(viewContainer, template, differs);
    }
}
