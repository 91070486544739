import { Entity } from "../../core/models/entity.model";

export class Processor extends Entity {
    name: string;

    constructor(processor?: Partial<Processor>) {
        super(processor);

        if (processor) {
            Object.assign(this, processor);
        }
    }
}
