import { Document } from "../../documents/models/document.model";
import { Image } from "../../documents/models/image.model";
import { Vehicle } from "./vehicle.model";

export class VehicleRegistrationImage extends Image {
    static readonly Category = "image.vehicle.registration";

    constructor(image?: Partial<Document | Image | VehicleRegistrationImage>) {
        super(image);
        this.category = VehicleRegistrationImage.Category;
    }

    set vehicle(vehicle: Vehicle) {
        if (vehicle != null) {
            this.directory = `/vehicles/${vehicle.uuid}/`;
        } else {
            this.directory = null;
        }

        this.filename = "registration";
        this.path = this.directory + this.filename;
    }
}
