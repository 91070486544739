/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes,extraRequire,uselessCode} checked by tsc
 */
import { Component, Input } from "@angular/core";
export class LineSeparatorComponent {
    constructor() { }
}
LineSeparatorComponent.decorators = [
    { type: Component, args: [{
                selector: "line-separator",
                template: "\n<div class=\"line-separator\">\n    <div class=\"strike\">\n        <span>{{text |\u00A0translate}}</span>\n    </div>\n</div>\n",
                styles: [".line-separator {\n  display: block;\n}\n.line-separator .strike {\n  display: block;\n  text-align: center;\n  overflow: hidden;\n  white-space: nowrap;\n}\n.line-separator .strike > span {\n  position: relative;\n  display: inline-block;\n  font-weight: bold;\n}\n.line-separator .strike > span:before,\n.line-separator .strike > span:after {\n  content: \"\";\n  position: absolute;\n  top: 50%;\n  width: 9999px;\n  height: 1px;\n  background: rgba(255, 255, 255, 0.6);\n}\n.line-separator .strike > span:before {\n  right: 100%;\n  margin-right: 15px;\n}\n.line-separator .strike > span:after {\n  left: 100%;\n  margin-left: 15px;\n}\n\n/*# sourceMappingURL=line-separator.css.map */\n"]
            }] }
];
/** @nocollapse */
LineSeparatorComponent.ctorParameters = () => [];
LineSeparatorComponent.propDecorators = {
    text: [{ type: Input }]
};
if (false) {
    /** @type {?} */
    LineSeparatorComponent.prototype.text;
}
