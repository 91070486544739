import { isEmpty } from "@tcn/ngx-common";
import { formatISO, parseISO } from "date-fns";

import { Address } from "../../core/models/address.model";
import { ContactInfo } from "../../core/models/contact-info.model";
import { Entity } from "../../core/models/entity.model";

export class ContactPerson extends Entity {
    refId: string;

    birthday: Date;
    note: string;
    tags: string;
    address: Address;
    contactInfo: ContactInfo;
    privateContactInfo: ContactInfo;

    constructor(contact?: Partial<ContactPerson>) {
        super(contact);

        if (contact) {
            Object.assign(this, contact);

            this.birthday = contact.birthday
                ? new Date(contact.birthday)
                : null;

            this.address = contact.address
                ? new Address(contact.address)
                : new Address();

            this.contactInfo = contact.contactInfo
                ? new ContactInfo(contact.contactInfo)
                : new ContactInfo();

            this.privateContactInfo = contact.privateContactInfo
                ? new ContactInfo(contact.privateContactInfo)
                : new ContactInfo();
        }

        // Default values.
        this.address = this.address || new Address();
        this.contactInfo = this.contactInfo || new ContactInfo();
    }

    get birthdayISO(): string {
        return this.birthday ? formatISO(this.birthday) : null;
    }

    set birthdayISO(date: string) {
        this.birthday = !isEmpty(date) ? parseISO(date) : null;
    }
}
