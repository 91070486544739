import { isEmpty } from "@tcn/ngx-common";
import { Entity } from "../../core/models/entity.model";

export function tagsToString(tags: Tag[], separator = ",") {
    if (isEmpty(tags)) {
        return null;
    }

    const value = tags.map((tag) => tag.name).join(separator);
    return value;
}

export function tagsFromString(tags: string, separator = ",") {
    if (isEmpty(tags)) {
        return [];
    }

    const value = tags
        .split(separator)
        .map((tag) => new Tag({ name: tag.trim() }));

    return value;
}

export class Tag extends Entity {
    createdOn: Date;
    refId: string;
    name: string;
    target: string;

    constructor(tag?: Partial<Tag>) {
        super(tag);

        if (tag) {
            Object.assign(this, tag);
            this.createdOn = tag.createdOn ? new Date(tag.createdOn) : null;
        }

        // Default values
        this.createdOn = this.createdOn || new Date();
    }
}
