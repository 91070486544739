import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isEmpty } from "@tcn/ngx-common";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { DataField } from "../../core/models/datafield.model";
import { ImportError } from "../../core/models/importerror.model";
import { QueryResponse } from "../../core/models/query-response.model";
import { Query } from "../../core/models/query.model";
import { removeKeys } from "../../utils/utils-fns";
import { Customer } from "../models/customer.model";

export class CustomerQuery extends Query {
    deleted: boolean;
    search: string;
    identity: string;
    customerNumber: string;
    branch: string;
    updatedAfter: Date;
    dataFields: Array<DataField>;

    constructor(query?: Partial<CustomerQuery>) {
        super(query);
        this.assign(query);
    }

    assign(query: Partial<CustomerQuery>): CustomerQuery {
        if (query) {
            Object.assign(this, query);
        }

        this.sortBy = this.sortBy || "name";
        this.sortType = this.sortType || "asc";

        return this;
    }
    get empty(): boolean {
        const empty =
            isEmpty(this.search) &&
            isEmpty(this.customerNumber) &&
            isEmpty(this.branch) &&
            isEmpty(this.updatedAfter) &&
            isEmpty(this.dataFields);

        return empty;
    }
}

export class GetCustomerOptions {
    withOptions?: boolean = false;

    constructor(options?: Partial<GetCustomerOptions>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

export class SaveCustomerOptions {
    mergeCustomer = false;
    mergeDatafields = false;
    returnCustomer = true;

    constructor(options?: Partial<SaveCustomerOptions>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

export class CustomerImportResponse {
    imported: Customer[] = [];
    failed: Customer[] = [];
    totalCount: number;
    importedCount: number;
    failedCount: number;
    errors: ImportError[] = [];

    constructor(options?: Partial<CustomerImportResponse>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

@Injectable({ providedIn: "root" })
export class CustomersService {
    constructor(protected http: HttpClient) {}

    queryCustomers(query: CustomerQuery): Observable<QueryResponse<Customer>> {
        return this.http.post("neonis.api.customers.v1/query", query).pipe(
            map(
                (response: any) =>
                    new QueryResponse<Customer>({
                        ...response,
                        data: response.data.map(
                            (customer) => new Customer(customer)
                        ),
                    })
            )
        );
    }

    getCustomer(
        customerId: string,
        options: GetCustomerOptions = new GetCustomerOptions()
    ): Observable<Customer> {
        const params = new HttpParams().set(
            "withOptions",
            options.withOptions.toString()
        );

        return this.http
            .get(`neonis.api.customers.v1/${customerId}`, { params })
            .pipe(map((response) => new Customer(response)));
    }

    saveCustomer(
        customer: Customer,
        options?: SaveCustomerOptions
    ): Observable<Customer> {
        options = options || new SaveCustomerOptions();

        const httpOptions = {
            headers: new HttpHeaders({
                "NEO-MERGE-CUSTOMER": options.mergeCustomer.toString(),
                "NEO-MERGE-DATAFIELDS": options.mergeDatafields.toString(),
                "NEO-IMPORT-RETURN-CUSTOMER-STATE":
                    options.returnCustomer.toString(),
                "NEO-IMPORT-ERROR-STATUS": "500",
            }),
        };

        const data = removeKeys(
            ["_messages", "options"],
            JSON.parse(JSON.stringify(customer))
        );

        const request = {
            data: [data],
        };

        return this.http
            .post<CustomerImportResponse>(
                "neonis.api.customers.v1/import",
                request,
                httpOptions
            )
            .pipe(
                map((response) => {
                    return new Customer(response.imported[0]);
                })
            );
    }

    deleteCustomer(customerId: string): Observable<any> {
        return this.http.delete(`neonis.api.customers.v1/${customerId}`);
    }
}
