export class ImportError {
    referenceId: string;
    message: string;
    refId: string;
    id: string;
    uuid: string;

    constructor(options?: Partial<ImportError>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}
