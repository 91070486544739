import { IQueryResponse } from './query-response.interface';

export class QueryResponse<T> implements IQueryResponse<T> {

    total = 0;
    data: Array<T>;
    first = 0;
    max = 25;

    constructor(response?: Partial<QueryResponse<T>>) {
        if (response) {
            Object.assign(this, response);
            this.data = [...response.data || []];
        }
    }

    get hasMore(): boolean {
        return (this.first + this.max) < this.total;
    }

}
