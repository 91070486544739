export function Delay(delay: number): PropertyDecorator {
    return function decorate(
        target: Object,
        key: string | symbol | number,
        descriptor?: PropertyDescriptor
    ): PropertyDescriptor {
        let originalMethod: Function;

        const wrapped = function(this: any, ...args: any[]) {
            setTimeout(() => {
                originalMethod.apply(this, args);
            }, delay);
        };

        descriptor = descriptor || Object.getOwnPropertyDescriptor(target, key);
        if (descriptor === undefined) {
            const dispatchDescriptor: PropertyDescriptor = {
                get: () => wrapped,
                set: setMethod => (originalMethod = setMethod)
            };

            Object.defineProperty(target, key, dispatchDescriptor);
            return dispatchDescriptor;
        } else {
            originalMethod = descriptor.value;
            descriptor.value = wrapped;
            return descriptor;
        }
    };
}
