import {
    Comparator,
    Selector,
    Transformer
} from "@angular-redux/store/components/selectors";

import { Subscription } from "rxjs";
import { getInstanceSelection } from "./helper";

export interface SelectAndSubscribeOptions {
    comparator?: Comparator;
    subscribeOn?: string;
    unSubscribeOn?: string;
}

const DEFAULT_OPTIONS: SelectAndSubscribeOptions = {
    subscribeOn: "ngOnInit",
    unSubscribeOn: "ngOnDestroy"
};

/**
 * Selects and subscribe to an observable from the store, and attaches it
 * to the decorated property.
 *
 * The subscription is executed in the ngOnInit method.
 * The unsubscription is executed in the ngOnDestroy method.
 *
 * @param selector
 * A selector function, property name string, or property name path
 * (array of strings/array indices) that locates the store data to be
 * selected
 *
 * @param options
 * subscribeOn: The lifecycle method hook in which the subscription is executed, default is ngOnInit
 * unSubscribeOn: The lifecycle method hook in which the unsubscription is executed, default is ngOnDestroy
 * comparator: Function used to determine if this selector has changed.
 */
export function SelectAndSubscribe<T>(
    selector?: Selector<any, T>,
    options: SelectAndSubscribeOptions = Object.assign({}, DEFAULT_OPTIONS)
) {
    return (target: any, key: string): void => {
        const adjustedSelector = selector
            ? selector
            : key.lastIndexOf("$") === key.length - 1
            ? key.substring(0, key.length - 1)
            : key;

        decorate(
            adjustedSelector,
            undefined,
            options.comparator,
            options.subscribeOn,
            options.unSubscribeOn
        )(target, key);
    };
}

function decorate(
    selector: Selector<any, any>,
    transformer?: Transformer<any, any>,
    comparator?: Comparator,
    subscribeOn?: string,
    unSubscribeOn: string = "ngOnDestroy"
): PropertyDecorator {
    let val: any;
    let subscription: Subscription | undefined;

    return function decorator(target: any, key): void {
        function getter(this: any) {
            if (subscription) {
                return val;
            }

            if (!subscribeOn) {
                subscription = getInstanceSelection(
                    this,
                    key,
                    selector,
                    transformer,
                    comparator
                ).subscribe((newVal: any) => (val = newVal));
            }
        }

        // Replace decorated property with a getter that returns the observable.
        if (delete target[key]) {
            Object.defineProperty(target, key, {
                get: getter,
                enumerable: true,
                configurable: true
            });
        }

        const oldSubscribeMethod = target[subscribeOn || ""]
            ? target[subscribeOn || ""]
            : () => undefined;

        if (subscribeOn && delete target[subscribeOn]) {
            const newMethod = function() {
                if (!subscription) {
                    subscription = getInstanceSelection(
                        this,
                        key,
                        selector,
                        transformer,
                        comparator
                    ).subscribe((newVal: any) => (val = newVal));
                }

                oldSubscribeMethod.bind(this)();
            };

            Object.defineProperty(target, subscribeOn, {
                value: newMethod
            });
        }

        const oldUnSubscribeMethod = target[unSubscribeOn]
            ? target[unSubscribeOn]
            : () => undefined;

        if (delete target[unSubscribeOn]) {
            const newMethod = function() {
                if (subscription) {
                    subscription.unsubscribe();
                    subscription = undefined;
                }

                oldUnSubscribeMethod.bind(this)();
            };

            Object.defineProperty(target, unSubscribeOn, {
                value: newMethod
            });
        }
    };
}
