import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { map } from "rxjs/operators";
import { DataField } from "../..//core/models/datafield.model";
import { ImportError } from "../../core/models/importerror.model";
import { QueryResponse } from "../../core/models/query-response.model";
import { Query } from "../../core/models/query.model";
import {
    DocumentQuery,
    DocumentsService,
} from "../../documents/services/documents.service";
import { removeKeys } from "../../utils/utils-fns";
import { FleetCardImage } from "../models/fleet-card-image";
import { LeasingCardImage } from "../models/leasing-card-image";
import { VehicleRegistrationImage } from "../models/vehicle-registration-image";
import { Vehicle } from "../models/vehicle.model";
import { VehicleType } from "../models/vehicle-type.model";
import { Engine } from "../models/engine.model";
import { VehicleImage } from "../models/vehicle-image.model";
import { isEmpty } from "@tcn/ngx-common";
import { da } from "date-fns/locale";

export class VehicleQuery extends Query {
    search?: string;
    identity?: string;
    tagNumber?: string;
    customer?: string;
    branch?: string;
    updatedAfter?: Date;
    dataFields?: Array<DataField>;
    withBranch?: boolean;
    withCustomer?: boolean;
    withDriver?: boolean;

    constructor(query?: Partial<VehicleQuery>) {
        super(query);

        if (query) {
            Object.assign(this, query);
        }

        this.sortBy = this.sortBy || "tagNumber";
        this.sortType = this.sortType || "asc";
    }

    assign(query: Partial<VehicleQuery>): VehicleQuery {
        if (query) {
            Object.assign(this, query);
        }

        this.sortBy = this.sortBy;
        this.sortType = this.sortType || "asc";

        return this;
    }

    get empty(): boolean {
        const empty =
            isEmpty(this.search) &&
            isEmpty(this.tagNumber) &&
            isEmpty(this.customer) &&
            isEmpty(this.branch) &&
            isEmpty(this.updatedAfter) &&
            isEmpty(this.dataFields) &&
            isEmpty(this.dataFields);

        return empty;
    }
}

export class GetVehicleOptions {
    withBranch = false;
    withCustomer = false;
    withDriver = false;

    constructor(options?: Partial<GetVehicleOptions>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

export class SaveVehicleOptions {
    mergeVehicle = false;
    mergeDatafields = true;
    returnVehicle = true;

    importCustomer = false;
    mergeCustomer = true;
    mergeCustomerDatafields = true;

    constructor(options?: Partial<SaveVehicleOptions>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

export class VehicleImportResponse {
    imported: Vehicle[] = [];
    failed: Vehicle[] = [];
    totalCount: number;
    importedCount: number;
    failedCount: number;
    errors: ImportError[] = [];

    constructor(options?: Partial<VehicleImportResponse>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

@Injectable({ providedIn: "root" })
export class VehiclesService {
    constructor(
        protected http: HttpClient,
        protected documentService: DocumentsService
    ) {}

    queryVehicles(query: VehicleQuery): Observable<QueryResponse<Vehicle>> {
        return this.http.post("neonis.api.vehicles.v1/query", query).pipe(
            map(
                (response: any) =>
                    new QueryResponse<Vehicle>({
                        ...response,
                        data: response.data.map(
                            (vehicle) => new Vehicle(vehicle)
                        ),
                    })
            )
        );
    }

    getVehicle(
        vehicleId: string,
        options: GetVehicleOptions = new GetVehicleOptions()
    ): Observable<Vehicle> {
        const params = new HttpParams()
            .set("withBranch", options.withBranch.toString())
            .set("withDriver", options.withCustomer.toString())
            .set("withCustomer", options.withCustomer.toString());

        return this.http
            .get(`neonis.api.vehicles.v1/${vehicleId}`, { params })
            .pipe(map((response) => new Vehicle(response)));
    }

    saveVehicle(
        vehicle: Vehicle,
        options?: SaveVehicleOptions
    ): Observable<Vehicle> {
        options = options || new SaveVehicleOptions();

        const httpOptions = {
            headers: new HttpHeaders({
                "NEO-MERGE-VEHICLE": options.mergeVehicle.toString(),
                "NEO-MERGE-DATAFIELDS": options.mergeDatafields.toString(),
                "NEO-IMPORT-RETURN-VEHICLE-STATE":
                    options.returnVehicle.toString(),

                "NEO-IMPORT-CUSTOMER": options.importCustomer.toString(),
                "NEO-MERGE-CUSTOMER": options.mergeCustomer.toString(),
                "NEO-MERGE-CUSTOMER-DATAFIELDS":
                    options.mergeCustomerDatafields.toString(),

                "NEO-IMPORT-ERROR-STATUS": "500",
            }),
        };

        const data = removeKeys(
            ["_messages", "_identification"],
            JSON.parse(JSON.stringify(vehicle))
        );

        if(data.customer){
            delete data.customer.options
        }

        const request = {
            data: [data],
        };

        return this.http
            .post<VehicleImportResponse>(
                "neonis.api.vehicles.v1/import",
                request,
                httpOptions
            )
            .pipe(
                map((response) => {
                    return new Vehicle(response.imported[0]);
                })
            );
    }

    getImages(
        vehicle: Vehicle,
        bucket = ""
    ): Observable<QueryResponse<VehicleImage>> {
        const path = `${bucket}/vehicles/${vehicle.uuid}/*`;
        const docQuery = new DocumentQuery({
            path: path,
        });

        return this.documentService.queryDocuments(docQuery).pipe(
            map(
                (rsp) =>
                    new QueryResponse<any>({
                        ...rsp,
                        data: rsp.data.map((doc) => new VehicleImage(doc)),
                    })
            )
        );
    }

    getRegistrationImage(
        vehicle: Vehicle,
        bucket = ""
    ): Observable<VehicleRegistrationImage> {
        const path = `${bucket}/vehicles/${vehicle.uuid}/registration`;
        const docQuery = new DocumentQuery({
            path: path,
        });

        return this.documentService
            .queryDocuments(docQuery)
            .pipe(
                map((rsp) =>
                    rsp.total > 0
                        ? new VehicleRegistrationImage(rsp.data[0])
                        : null
                )
            );
    }

    getLeasingCardImages(
        vehicle: Vehicle,
        bucket = ""
    ): Observable<Array<LeasingCardImage>> {
        const path = `${bucket}/vehicles/${vehicle.uuid}/leasingcard/*`;
        const docQuery = new DocumentQuery({
            path: path,
        });

        return this.documentService
            .queryDocuments(docQuery)
            .pipe(
                map((rsp) => rsp.data.map((doc) => new LeasingCardImage(doc)))
            );
    }

    getFleetCardImages(
        vehicle: Vehicle,
        bucket = ""
    ): Observable<Array<FleetCardImage>> {
        const path = `${bucket}/vehicles/${vehicle.uuid}/fleetcard/*`;
        const docQuery = new DocumentQuery({
            path: path,
        });

        return this.documentService
            .queryDocuments(docQuery)
            .pipe(map((rsp) => rsp.data.map((doc) => new FleetCardImage(doc))));
    }

    getVehicleInfo(hsn: string, tsn: string): Observable<Vehicle[]> {
        return this.http
            .get<any>(`neonis.api.vehicles.info.v1/hsn/${hsn}/tsn/${tsn}`)
            .pipe(
                map((response) =>
                    response.map(
                        (info) =>
                            new Vehicle({
                                length: info.length,
                                width: info.width,
                                height: info.height,
                                buildYearStart: info.buildYearStart,
                                buildYearEnd: info.buildYearEnd,
                                maxSpeed: info.maxSpeed,
                                catType: info.catType,
                                powertrainType: info.powertrainType,
                                emissionClass: info.emissionClass,
                                emptyWeight: info.emptyWeight,
                                engine: new Engine({
                                    code: info.engineCode,
                                    fuelType: info.fuelType,
                                    capacity: info.engineCapacity,
                                    horsepower: info.ps,
                                    kilowatt: info.kw,
                                }),
                                type: new VehicleType({
                                    manufacturerName: info.manufacturerName,
                                    modelName: info.className,
                                    versionName: info.version,
                                    typeName: info.type,

                                    manufacturerCode: info.hsn,
                                    typeCode: info.tsn,
                                    versionCode: info.vsn,
                                    typeAndVersionCode: `${info.tsn}${info.vsn}`,
                                }),
                            })
                    )
                )
            );
    }
}
