import { NgModule } from "@angular/core";

import { WheelsApiModule } from "./wheels/wheels.module";
import { VehiclesApiModule } from "./vehicles/vehicles.module";
import { VouchersApiModule } from "./vouchers/vouchers.module";
import { DocumentsApiModule } from "./documents/documents.module";
import { CustomersApiModule } from "./customers/customers.module";
import { BookingsApiModule } from "./bookings/bookings.module";

@NgModule({
    imports: [
        BookingsApiModule,
        CustomersApiModule,
        DocumentsApiModule,
        VouchersApiModule,
        VehiclesApiModule,
        WheelsApiModule
    ],
    exports: [
        BookingsApiModule,
        CustomersApiModule,
        DocumentsApiModule,
        VouchersApiModule,
        VehiclesApiModule,
        WheelsApiModule
    ]
})
export class NeonisApiModule {}
