export class Engine {
    code: string;
    capacity: number;
    fuelTypeName: string;
    fuelType: string;
    horsepower: number;
    rpm: number;
    kilowatt: number;

    constructor(engine?: Partial<Engine>) {
        if (engine) {
            Object.assign(this, engine);
        }
    }
}
