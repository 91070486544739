import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isEmpty } from "@tcn/ngx-common";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { QueryResponse } from "../../core/models/query-response.model";
import { Query } from "../../core/models/query.model";
import { ImportError } from "../../core/models/importerror.model";
import {
    Booking,
    BookingProgress,
    BookingState,
} from "../models/booking.model";

export class BookingQuery extends Query {
    identity?: string;
    deleted?: boolean;
    search?: string;

    updatedAfter?: Date;
    timeMin?: Date;
    timeMax?: Date;
    startTimeMin?: Date;
    startTimeMax?: Date;
    endTimeMin?: Date;
    endTimeMax?: Date;

    source?: string;
    state?: BookingState;
    progress?: BookingProgress;

    branch?: string;
    resource?: string;
    service?: string;
    customer?: string;
    vehicle?: string;
    storage?: string;

    hasStorage?: boolean;

    withBranch?: boolean;
    withUser?: boolean;
    withResource?: boolean;
    withCustomer?: boolean;
    withVehicle?: boolean;
    withStorage?: boolean;

    constructor(query?: Partial<BookingQuery>) {
        super(query);
        this.assign(query);
    }

    assign(query: Partial<BookingQuery>): BookingQuery {
        if (query) {
            Object.assign(this, query);
        }

        this.sortBy = this.sortBy || "startDate";
        this.sortType = this.sortType || "asc";

        return this;
    }

    get empty(): boolean {
        const empty =
            isEmpty(this.identity) &&
            isEmpty(this.deleted) &&
            isEmpty(this.search) &&
            isEmpty(this.updatedAfter) &&
            isEmpty(this.timeMin) &&
            isEmpty(this.timeMax) &&
            isEmpty(this.startTimeMin) &&
            isEmpty(this.startTimeMax) &&
            isEmpty(this.endTimeMin) &&
            isEmpty(this.endTimeMax) &&
            isEmpty(this.source) &&
            isEmpty(this.state) &&
            isEmpty(this.progress) &&
            isEmpty(this.branch) &&
            isEmpty(this.resource) &&
            isEmpty(this.service) &&
            isEmpty(this.customer) &&
            isEmpty(this.vehicle) &&
            isEmpty(this.storage) &&
            isEmpty(this.hasStorage);

        return empty;
    }
}

export class GetBookingOptions {
    withBranch?: boolean = false;
    withResoure?: boolean = false;
    withUser?: boolean = false;
    withCustomer?: boolean = false;
    withVehicle?: boolean = false;
    withStorage?: boolean = false;

    constructor(options?: Partial<GetBookingOptions>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

export class SaveBookingOptions {
    mergeBooking = true;
    importServices = false;
    importDataFields = false;
    returnBooking = true;
    allowOverlapping = true;

    importCustomer = false;
    mergeCustomer = true;

    importVehicle = false;
    mergeVehicle = false;

    importStorage = false;
    mergeStorage = false;
    returnStorage = true;

    constructor(options?: Partial<SaveBookingOptions>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

export class BookingImportResponse {
    imported: Booking[] = [];
    failed: Booking[] = [];
    totalCount: number;
    importedCount: number;
    failedCount: number;
    errors: ImportError[] = [];

    constructor(options?: Partial<BookingImportResponse>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

@Injectable({
    providedIn: "root",
})
export class BookingsService {
    constructor(protected http: HttpClient) {}

    queryBookings(query: BookingQuery): Observable<QueryResponse<Booking>> {
        const params = new HttpParams();

        return this.http
            .post("neonis.api.bookings.v1/query", query, { params })
            .pipe(
                map(
                    (response: any) =>
                        new QueryResponse<Booking>({
                            total: response.total,
                            data: response.data.map(($) => new Booking($)),
                        })
                )
            );
    }

    getBooking(
        bookingId: string,
        options: GetBookingOptions = new GetBookingOptions()
    ): Observable<Booking> {
        const params = new HttpParams()
            .set("withBranch", options.withBranch.toString())
            .set("withResource", options.withResoure.toString())
            .set("withUser", options.withUser.toString())
            .set("withCustomer", options.withCustomer.toString())
            .set("withVehicle", options.withVehicle.toString())
            .set("withStorage", options.withStorage.toString());

        return this.http
            .get(`neonis.api.bookings.v1/${bookingId}`, { params })
            .pipe(map((response) => new Booking(response)));
    }

    saveBooking(
        booking: Booking,
        options?: SaveBookingOptions
    ): Observable<Booking> {
        options = options || new SaveBookingOptions();

        const httpOptions = {
            headers: new HttpHeaders({
                "NEO-MERGE-BOOKING": options.mergeStorage.toString(),
                "NEO-IMPORT-SERVICES": options.importServices.toString(),
                "NEO-IMPORT-DATAFIELDS": options.importDataFields.toString(),

                "NEO-IMPORT-CUSTOMER": options.importCustomer.toString(),
                "NEO-MERGE-CUSTOMER": options.mergeCustomer.toString(),

                "NEO-IMPORT-VEHICLE": options.importVehicle.toString(),
                "NEO-MERGE-VEHICLE": options.mergeVehicle.toString(),

                "NEO-IMPORT-WHEELSTORAGE": options.importStorage.toString(),
                "NEO-MERGE-WHEELSTORAGE": options.mergeStorage.toString(),

                "NEO-IMPORT-RETURN-BOOKING-STATE":
                    options.returnBooking.toString(),
                "NEO-IMPORT-BOOKING-ALLOW-OVERLAPPING":
                    options.allowOverlapping.toString(),

                "NEO-IMPORT-ERROR-STATUS": "500",
            }),
        };

        const data = JSON.parse(JSON.stringify(booking));

        if (data.customer) {
            delete data.customer.options;
        }

        const request = {
            data: [data],
        };

        return this.http
            .post<BookingImportResponse>(
                "neonis.api.bookings.v1/import",
                request,
                httpOptions
            )
            .pipe(
                map((response) => {
                    return new Booking(response.imported[0]);
                })
            );
    }
}
