import { Branch } from "./../../core/models/branch.model";
import { isEmpty } from "@tcn/ngx-common";
import {
    addDays,
    differenceInMonths,
    endOfDay,
    formatISO,
    isBefore,
    isFuture,
    isPast,
    parseISO,
} from "date-fns";
import { Entity } from "../../core/models/entity.model";
import {
    compareSeverity,
    Message,
    Severity,
} from "../../core/models/message.model";
import { Customer } from "../../customers/models/customer.model";
import { Vehicle } from "../../vehicles/models/vehicle.model";
import {
    StorableState,
    storableStateIndex,
    storableStateValue,
} from "./storable.model";
import { StorageService } from "./storage-service.model";
import { StoredWheel, WheelPosition } from "./stored-wheel.model";

export type StorageType = "SUMMER" | "WINTER" | "ALLSEASON";
export type StorageStatus = "CHECK" | "INWORK" | "DONE";
export type RimType = "STEEL" | "ALU" | "NONE";

export class WheelStorage extends Entity {
    static readonly TagTarget = "storage";

    refId: string;
    refId2: string;
    refId3: string;

    createdOn: Date;
    createdBy: string;
    updatedOn: Date;
    updatedBy: string;
    syncedOn: Date;

    branch: Branch;
    storageNumber: string;
    location: string;
    comment: string;
    active: boolean;
    type: StorageType;
    status: StorageStatus;
    orderNumber: string;
    storageDate: Date;
    swapDate: Date;
    endDate: Date;
    requiredDate: Date;
    restoreable: boolean;
    withoutRims: boolean;
    rimType: RimType = "NONE";
    wheelNutCount: number;
    withWheelNuts: boolean;
    rimLockCount: number;
    withRimLocks: boolean;
    hubCapCount: number;
    withHubCaps: boolean;
    wheels: Array<StoredWheel> = [];
    customer: Customer;
    vehicle: Vehicle;
    services: Array<StorageService> = [];
    tags: string;
    marked: boolean;
    pickedUpDate: Date;
    measurementId: string;

    private _messages: Message[];

    constructor(storage?: Partial<WheelStorage>) {
        super(storage);

        if (storage) {
            Object.assign(this, storage);

            this.createdOn = storage.createdOn
                ? new Date(storage.createdOn)
                : null;

            this.updatedOn = storage.updatedOn
                ? new Date(storage.updatedOn)
                : null;

            this.syncedOn = storage.syncedOn
                ? new Date(storage.syncedOn)
                : null;

            this.branch = storage.branch ? new Branch(storage.branch) : null;

            this.storageDate = storage.storageDate
                ? new Date(storage.storageDate)
                : null;

            this.swapDate = storage.swapDate
                ? new Date(storage.swapDate)
                : null;

            this.endDate = storage.endDate ? new Date(storage.endDate) : null;

            this.requiredDate = storage.requiredDate
                ? new Date(storage.requiredDate)
                : null;

            this.pickedUpDate = storage.pickedUpDate
                ? new Date(storage.pickedUpDate)
                : null;

            this.customer = storage.customer
                ? new Customer(storage.customer)
                : null;

            this.vehicle = storage.vehicle
                ? new Vehicle(storage.vehicle)
                : null;

            this.wheels = storage.wheels
                ? storage.wheels.map((wheel) => new StoredWheel(wheel))
                : [];

            this.services = storage.services
                ? storage.services.map((service) => new StorageService(service))
                : [];
        }

        // Default values
        this.active = this.active != null ? this.active : false;
        this.rimType = this.rimType || "NONE";
        this.wheels = this.wheels || [];
        this.services = this.services || [];
    }

    getWheelByPosition(postion: WheelPosition): StoredWheel {
        return this.wheels.find((wheel) => wheel.position === postion);
    }

    getTyreByPosition(postion: WheelPosition) {
        const wheel = this.getWheelByPosition(postion);
        return wheel ? wheel.tyre : null;
    }

    getRimByPosition(postion: WheelPosition) {
        const wheel = this.getWheelByPosition(postion);
        return wheel ? wheel.rim : null;
    }

    get monthsUntilSwapDate(): number {
        if (this.storageDate == null || this.swapDate == null) {
            return null;
        }

        return differenceInMonths(this.swapDate, this.storageDate);
    }

    get monthsUntilEndDate(): number {
        if (this.storageDate == null || this.endDate == null) {
            return null;
        }

        return differenceInMonths(this.storageDate, this.endDate);
    }

    get overdue(): boolean {
        if (!this.active) {
            return false;
        }

        if (this.storageDate == null || this.endDate == null) {
            return false;
        }

        return isPast(this.endDate);
    }

    get worstWheelState(): StorableState {
        const worstIndex = Math.max(
            ...this.wheels.map((wheel) => storableStateIndex(wheel.state)),
            0
        );

        return storableStateValue(worstIndex);
    }

    get worstTyreState(): StorableState {
        const worstIndex = Math.max(
            ...this.wheels.map((wheel) => storableStateIndex(wheel.tyre.state)),
            0
        );

        return storableStateValue(worstIndex);
    }

    get worstTyreAge(): number {
        return Math.max(...this.wheels.map((wheel) => wheel.tyre.age), 0);
    }

    get worstProfileDepth(): number {
        return Math.min(
            ...this.wheels.map((wheel) => wheel.tyre.profileDepth),
            Number.MAX_VALUE
        );
    }

    get worstRimState(): StorableState {
        const worstIndex = Math.max(
            ...this.wheels.map((wheel) => storableStateIndex(wheel.rim.state)),
            0
        );

        return storableStateValue(worstIndex);
    }

    get storageDateISO(): string {
        return this.storageDate ? formatISO(this.storageDate) : null;
    }

    set storageDateISO(date: string) {
        this.storageDate = !isEmpty(date) ? parseISO(date) : null;
    }

    get endDateISO(): string {
        return this.endDate ? formatISO(this.endDate) : null;
    }

    set endDateISO(date: string) {
        this.endDate = !isEmpty(date) ? parseISO(date) : null;
    }

    get swapDateISO(): string {
        return this.swapDate ? formatISO(this.swapDate) : null;
    }

    set swapDateISO(date: string) {
        this.swapDate = !isEmpty(date) ? parseISO(date) : null;
    }

    isRequired(dayRange = 1) {
        return (
            this.requiredDate != null &&
            isFuture(this.requiredDate) &&
            isBefore(this.requiredDate, endOfDay(addDays(new Date(), dayRange)))
        );
    }

    get messages(): Message[] {
        if (!this._messages) {
            this.refreshMessages();
        }

        return this._messages;
    }

    refreshMessages(minSeverity: Severity = "info"): Message[] {
        this._messages = [];

        if (this.overdue && compareSeverity("warning", minSeverity) >= 0) {
            this._messages.push(
                new Message({
                    severity: "warning",
                    message: "message.storage.overdue",
                })
            );
        }

        return this._messages;
    }
}
