import { VoucherItem } from "./voucher-item.model";
import { Entity } from "../../core/models/entity.model";
import { Address } from "../../core/models/address.model";
import { BankInfo } from "../../core/models/bank-info.model";
import { StorageType } from "../../wheels/models/wheelstorage.model";

export interface VoucherCustomerInfo {
    customerNumber: string;
    branch?: string;
    tiein?: string;
    dealer?: boolean;
}

export interface VoucherVehicleInfo {
    manufacturer?: string;
    tagNumber: string;
    tagNumberFormatted: string;
}

export interface VoucherStorageInfo {
    storageNumber: string;
    type: StorageType;
}

export class Voucher extends Entity {
    createdOn: Date;
    createdBy: string;
    updatedOn: Date;
    updatedBy: string;

    refId: string;
    type: string;
    voucherNumber: string;
    orderNumber: string;
    note: string;
    manualProcessing: boolean;
    branchNumber: string;

    sellerName: string;
    distributorId: string;
    distributionChannel: string;

    vatNumber: string;
    billingAddress: Address;
    shippingAddress: Address;
    bankInfo: BankInfo;

    customer?: VoucherCustomerInfo;
    vehicle?: VoucherVehicleInfo;
    storage?: VoucherStorageInfo;

    items: VoucherItem[];

    netPrice: number;
    grossPrice: number;
    vatAmount: number;
    currencyCode: string;
    currencySign: string;

    constructor(voucher?: Partial<Voucher>) {
        super(voucher);

        if (voucher) {
            Object.assign(this, voucher);

            this.createdOn = voucher.createdOn
                ? new Date(voucher.createdOn)
                : null;

            this.updatedOn = voucher.updatedOn
                ? new Date(voucher.updatedOn)
                : null;

            this.billingAddress = new Address(voucher.billingAddress);
            this.shippingAddress = new Address(voucher.shippingAddress);
            this.bankInfo = new BankInfo(voucher.bankInfo);

            this.items = [...(voucher.items || [])].map(
                (item) => new VoucherItem(item)
            );
        }

        // Default values
        this.createdOn = this.createdOn || new Date();
        this.billingAddress = this.billingAddress || new Address();
        this.shippingAddress = this.shippingAddress || new Address();
        this.bankInfo = this.bankInfo || new BankInfo();
        this.items = this.items || [];
    }

    calculate() {
        this.netPrice = 0;
        this.grossPrice = 0;
        this.vatAmount = 0;

        this.items.forEach((item) => {
            item.calculate();

            this.netPrice += item.totalNetPrice || 0;
            this.grossPrice += item.totalGrossPrice || 0;
            this.vatAmount += item.totalVatAmount || 0;
        });
    }
}
