import { formatDate } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { LocaleConfigService } from "../../services/locale-config.service";

@Pipe({
    name: "localeDate"
})
export class LocaleDatePipe implements PipeTransform {
    constructor(protected localeConfig: LocaleConfigService) {}

    transform(value: any, format = "shortDate") {
        if (!value) {
            return "";
        }

        if (!format) {
            format = "shortDate";
        }

        const lang = this.localeConfig.getActiveLanguage();
        return formatDate(value, format, lang);
    }
}
