import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { isEmpty } from "@tcn/ngx-common";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ImportError } from "../../core/models/importerror.model";
import { QueryResponse } from "../../core/models/query-response.model";
import { Query } from "../../core/models/query.model";
import {
    DocumentQuery,
    DocumentsService,
} from "../../documents/services/documents.service";
import { removeKeys } from "../../utils/utils-fns";
import { RdksType } from "../../vehicles/models/vehicle.model";
import { StorableState } from "../models/storable.model";
import { WheelImage } from "../models/wheel-image.model";
import {
    RimType,
    StorageStatus,
    StorageType,
    WheelStorage,
} from "../models/wheelstorage.model";

export type StorageStatType =
    | "type"
    | "active"
    | "state"
    | "status"
    | "overdue/month"
    | "rim/type"
    | "rim/diameter"
    | "rim/manufacturer"
    | "tyre/manufacturer"
    | "tyre/diameter"
    | "tyre/profile/depth";

export class WheelStorageQuery extends Query {
    search?: string;
    identity?: string;
    type?: StorageType;
    active?: boolean;
    status?: StorageStatus;
    state?: StorableState;
    tyreDiameter?: string;
    tyreManufacturer?: string;
    minProfileDepth?: number;
    maxProfileDepth?: number;
    minTyreAge?: number;
    maxTyreAge?: number;
    rimType?: RimType;
    rdksType?: RdksType;
    overdue?: boolean;
    minOverdueMonths?: number;
    maxOverdueMonths?: number;
    orderNumber?: string;
    storageNumber?: string;
    location?: string;
    tags?: string[];
    marked?: boolean;
    minRequiredDate?: Date;
    maxRequiredDate?: Date;
    branch?: string;
    customer?: string;
    vehicle?: string;
    updatedAfter?: Date;

    withBranch?: boolean;
    withCustomer?: boolean;
    withVehicle?: boolean;

    constructor(query?: Partial<WheelStorageQuery>) {
        super(query);
        this.assign(query);
    }

    assign(query: Partial<WheelStorageQuery>): WheelStorageQuery {
        if (query) {
            Object.assign(this, query);
        }

        this.sortBy = this.sortBy;
        this.sortType = this.sortType || "asc";
        this.tags = this.tags || [];

        return this;
    }

    get empty(): boolean {
        const empty =
            isEmpty(this.search) &&
            isEmpty(this.type) &&
            isEmpty(this.active) &&
            isEmpty(this.status) &&
            isEmpty(this.state) &&
            isEmpty(this.tyreDiameter) &&
            isEmpty(this.tyreManufacturer) &&
            isEmpty(this.minProfileDepth) &&
            isEmpty(this.maxProfileDepth) &&
            isEmpty(this.minTyreAge) &&
            isEmpty(this.maxTyreAge) &&
            isEmpty(this.rimType) &&
            isEmpty(this.rdksType) &&
            isEmpty(this.overdue) &&
            isEmpty(this.minOverdueMonths) &&
            isEmpty(this.maxOverdueMonths) &&
            isEmpty(this.orderNumber) &&
            isEmpty(this.storageNumber) &&
            isEmpty(this.location) &&
            isEmpty(this.tags) &&
            isEmpty(this.marked) &&
            isEmpty(this.minRequiredDate) &&
            isEmpty(this.maxRequiredDate) &&
            isEmpty(this.branch) &&
            isEmpty(this.customer) &&
            isEmpty(this.vehicle) &&
            isEmpty(this.updatedAfter);

        return empty;
    }
}

export class GetStorageOptions {
    withBranch?: boolean = false;
    withCustomer?: boolean = false;
    withVehicle?: boolean = false;

    constructor(options?: Partial<GetStorageOptions>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

export class SaveStorageOptions {
    mergeStorage = false;
    returnStorage = true;

    importVehicle = false;
    mergeVehicle = false;

    importCustomer = false;
    mergeCustomer = true;
    mergeCustomerDatafields = true;

    constructor(options?: Partial<SaveStorageOptions>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

export class StorageImportResponse {
    imported: WheelStorage[] = [];
    failed: WheelStorage[] = [];
    totalCount: number;
    importedCount: number;
    failedCount: number;
    errors: ImportError[] = [];

    constructor(options?: Partial<StorageImportResponse>) {
        if (options) {
            Object.assign(this, options);
        }
    }
}

@Injectable({ providedIn: "root" })
export class WheelStoragesService {

    constructor(
        protected http: HttpClient,
        protected documentService: DocumentsService
    ) {}

    queryStorages(
        query: WheelStorageQuery
    ): Observable<QueryResponse<WheelStorage>> {
        const request: any = Object.assign({}, query);

        return this.http
            .post("neonis.api.wheelstorages.v1/query", request)
            .pipe(
                map((response: any) => {
                    return new QueryResponse<WheelStorage>({
                        ...response,
                        data: response.data.map(
                            (storage) => new WheelStorage(storage)
                        ),
                    });
                })
            );
    }

    getStorage(
        storageId: string,
        options: GetStorageOptions = new GetStorageOptions()
    ): Observable<WheelStorage> {
        const params = new HttpParams()
            .set("withBranch", options.withBranch.toString())
            .set("withCustomer", options.withCustomer.toString())
            .set("withVehicle", options.withVehicle.toString());

        return this.http
            .get(`neonis.api.wheelstorages.v1/${storageId}`, { params })
            .pipe(map((response) => new WheelStorage(response)));
    }

    saveStorage(
        storage: WheelStorage,
        options?: SaveStorageOptions
    ): Observable<WheelStorage> {
        options = options || new SaveStorageOptions();

        const httpOptions = {
            headers: new HttpHeaders({
                "NEO-MERGE-STORAGE": options.mergeStorage.toString(),
                "NEO-IMPORT-CUSTOMER": options.importCustomer.toString(),
                "NEO-MERGE-CUSTOMER": options.mergeCustomer.toString(),
                "NEO-MERGE-CUSTOMER-DATAFIELDS":
                    options.mergeCustomerDatafields.toString(),

                "NEO-IMPORT-VEHICLE": options.importVehicle.toString(),
                "NEO-MERGE-VEHICLE": options.mergeVehicle.toString(),
                "NEO-IMPORT-RETURN-STORAGE-STATE":
                    options.returnStorage.toString(),

                "NEO-IMPORT-ERROR-STATUS": "500",
            }),
        };

        const data = removeKeys(
            ["_messages", "_identification"],
            JSON.parse(JSON.stringify(storage))
        );

        if(data.customer){
            delete data.customer.options
        }

        const request = {
            data: [data],
        };

        return this.http
            .post<StorageImportResponse>(
                "neonis.api.wheelstorages.v1/import",
                request,
                httpOptions
            )
            .pipe(
                map((response) => {
                    return new WheelStorage(response.imported[0]);
                })
            );
    }

    deleteStorage(storageId: string): Observable<any> {
        return this.http.delete(`neonis.api.wheelstorages.v1/${storageId}`);
    }

    getTags(): Observable<string[]> {
        return this.http.get<string[]>(`neonis.api.wheelstorages.v1/tags`);
    }

    getImages(
        storage: WheelStorage,
        bucket = ""
    ): Observable<QueryResponse<WheelImage>> {
        const path = `${bucket}/storages/${storage.uuid}/*`;
        const docQuery = new DocumentQuery({
            path: path,
        });

        return this.documentService.queryDocuments(docQuery).pipe(
            map(
                (rsp) =>
                    new QueryResponse<any>({
                        ...rsp,
                        data: rsp.data.map((doc) => new WheelImage(doc)),
                    })
            )
        );
    }

    getStat(name: StorageStatType, type?: StorageType, branch?: string) {
        let params = new HttpParams();

        if (!isEmpty(type)) {
            params = params.set("type", type);
        }

        if (!isEmpty(branch)) {
            params = params.set("branch", branch);
        }

        return this.http.get<any>(`neonis.api.wheelstorages.v1/stats/${name}`, {
            params,
        });
    }
}
