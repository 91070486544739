export type Severity = "info" | "success" | "warning" | "danger";

export function severityIndex(severity: Severity): number {
    if (severity === "info") {
        return 0;
    }

    if (severity === "success") {
        return 1;
    }

    if (severity === "warning") {
        return 2;
    }

    if (severity === "danger") {
        return 3;
    }

    return 0;
}

export function severityValue(index: number): Severity {
    if (index === 0) {
        return "info";
    }

    if (index === 1) {
        return "success";
    }

    if (index === 2) {
        return "warning";
    }

    if (index === 3) {
        return "danger";
    }

    return "info";
}

export function compareSeverity(severity: Severity, other: Severity): number {
    const idx1 = severityIndex(severity);
    const idx2 = severityIndex(other);
    return idx1 - idx2;
}

export class Message {
    severity: Severity = "info";
    message: string;

    constructor(message?: Partial<Message>) {
        if (message) {
            Object.assign(this, message);
        }
    }
}
