import { Pipe, PipeTransform } from "@angular/core";
import { ApiConfigService } from "../../services/api-config.service";
import { ApiConfig } from "../../models/api-config.model";
import { isEmpty } from "ngx-common";

@Pipe({
    name: "apiAuth"
})
export class ApiAuthPipe implements PipeTransform {
    constructor(private apiConfig: ApiConfigService) {}

    transform(url: any) {
        if (!url) {
            return url;
        }

        const configs: ApiConfig[] = this.apiConfig.all();

        for (const config of configs) {
            if (config === null) {
                continue;
            }

            if (!config.auth.basic || !config.enabledForUrl(url)) {
                continue;
            }

            let scheme = "";
            if (url.indexOf("https://") === 0) {
                scheme = "https://";
            } else if (url.indexOf("http://") === 0) {
                scheme = "http://";
            }

            if (scheme) {
                url = url.replace(scheme, "");
            }

            const auth =
                encodeURIComponent(config.auth.basic.username) +
                ":" +
                encodeURIComponent(config.auth.basic.password);

            url = scheme + auth + "@" + url;
            url = config.parseUrl(url);
            break;
        }

        return url;
    }
}
