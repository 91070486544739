import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root"
})
export class LocaleConfigService {
    protected activeLang = "en";
    protected defaultLang = "en";

    setActiveLanguage(lang: string) {
        this.activeLang = lang || "en";
    }

    getActiveLanguage(): string {
        return this.activeLang;
    }

    setDefaultLanguage(lang: string) {
        this.defaultLang = lang || "en";
    }

    getDefaultLanguage(): string {
        return this.defaultLang;
    }
}
