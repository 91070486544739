import { isEmpty } from "@tcn/ngx-common";
import {
    addDays,
    endOfDay,
    format,
    formatISO,
    isBefore,
    isFuture,
    isPast,
    isThisMonth,
    parseISO,
    setMonth,
    setYear,
    startOfMonth,
} from "date-fns";
import { DataField } from "../../core/models/datafield.model";
import { Entity } from "../../core/models/entity.model";
import { compareSeverity, Message } from "../../core/models/message.model";
import { Customer } from "../../customers/models/customer.model";
import { Severity } from "./../../core/models/message.model";
import { Driver } from "./driver.model";
import { Engine } from "./engine.model";
import { VehicleCheck } from "./vehicle-check.model";
import { VehicleType } from "./vehicle-type.model";

function parseInspectionDate(value: string) {
    if (isEmpty(value)) {
        return null;
    }

    const parts = value.split("/");
    if (parts.length !== 2) {
        return null;
    }

    let date = startOfMonth(new Date());
    date = setMonth(date, parseInt(parts[0]) - 1);
    date = setYear(date, parseInt("20" + parts[1]));

    return date;
}

function formatInspectionDate(value: string | Date): string {
    const date = typeof value === "string" ? parseISO(value) : value;
    return date != null ? format(date, "MM/yy") : null;
}

export type RdksType = "NONE" | "ACTIVE" | "PASSIVE";

export class Vehicle extends Entity {
    refId: string;
    refId2: string;
    refId3: string;

    createdOn: Date;
    createdBy: string;
    updatedOn: Date;
    updatedBy: string;
    syncedOn: Date;

    tagNumber: string;
    tagNumberFormatted: string;
    vin: string;
    note: string;
    registration: Date;

    buildYear: number;
    buildYearStart: number;
    buildYearEnd: number;

    nextHU: string;
    nextAU: string;

    vehicleChecks: Array<VehicleCheck>;
    dataFields: Array<DataField>;

    fleetsVehicle: boolean;
    fleetsContractNumber: string;
    leasingVehicle: boolean;
    leasingContractNumber: string;

    mileage: number;
    lastMainteanceAircon: Date;
    lastInspection: Date;
    firstAidKitExpiry: Date;
    inspectionIntervall: number;
    maintenanceIntervallAircon: number;
    inspectionIntervallKm: number;

    rdksType: RdksType;
    powertrainType: string;
    emissionClass: string;
    length: number;
    height: number;
    width: number;
    emptyWeight: number;
    color: string;
    colorCode: string;
    aircon: boolean;
    maxSpeed: number;
    catType: string;
    wheelBase: number;
    typeNumber: string;

    type: VehicleType;
    engine: Engine;
    driver: Driver;
    customer: Customer;

    private _messages: Message[];

    constructor(vehicle?: Partial<Vehicle>) {
        super(vehicle);

        if (vehicle) {
            Object.assign(this, vehicle);

            this.createdOn = vehicle.createdOn
                ? new Date(vehicle.createdOn)
                : null;

            this.updatedOn = vehicle.updatedOn
                ? new Date(vehicle.updatedOn)
                : null;

            this.syncedOn = vehicle.syncedOn
                ? new Date(vehicle.syncedOn)
                : null;

            this.type = new VehicleType(vehicle.type);
            this.engine = new Engine(vehicle.engine);
            this.driver = new Driver(vehicle.driver);
            this.customer = new Customer(vehicle.customer);

            this.registration = vehicle.registration
                ? new Date(vehicle.registration)
                : null;

            this.lastMainteanceAircon = vehicle.lastMainteanceAircon
                ? new Date(vehicle.lastMainteanceAircon)
                : null;

            this.lastInspection = vehicle.lastInspection
                ? new Date(vehicle.lastInspection)
                : null;

            this.firstAidKitExpiry = vehicle.firstAidKitExpiry
                ? new Date(vehicle.firstAidKitExpiry)
                : null;

            this.dataFields = vehicle.dataFields
                ? vehicle.dataFields.map((field) => new DataField(field))
                : [];

            this.vehicleChecks = vehicle.dataFields
                ? vehicle.vehicleChecks.map((check) => new VehicleCheck(check))
                : [];
        }

        // Default values.
        this.driver = this.driver || new Driver();
        this.vehicleChecks = this.vehicleChecks || [];
        this.dataFields = this.dataFields || [];
        this.type = this.type || new VehicleType();
        this.engine = this.engine || new Engine();
        this.rdksType = this.rdksType || "NONE";
    }

    get registrationISO(): string {
        return this.registration ? formatISO(this.registration) : null;
    }

    set registrationISO(date: string) {
        this.registration = !isEmpty(date) ? parseISO(date) : null;
    }

    get nextHUDate(): Date {
        return parseInspectionDate(this.nextHU);
    }

    get nextHUDateISO(): string {
        const date = this.nextHUDate;
        return date ? formatISO(date) : null;
    }

    set nextHUDateISO(date: string) {
        this.nextHU = formatInspectionDate(date);
    }

    get nextAUDate(): Date {
        return parseInspectionDate(this.nextAU);
    }

    get nextAUDateISO(): string {
        const date = this.nextAUDate;
        return date ? formatISO(date) : null;
    }

    set nextAUDateISO(date: string) {
        this.nextAU = formatInspectionDate(date);
    }

    get lastInspectionISO(): string {
        return this.lastInspection ? formatISO(this.lastInspection) : null;
    }

    set lastInspectionISO(date: string) {
        this.lastInspection = !isEmpty(date) ? parseISO(date) : null;
    }

    get lastMainteanceAirconISO(): string {
        return this.lastMainteanceAircon
            ? formatISO(this.lastMainteanceAircon)
            : null;
    }

    set lastMainteanceAirconISO(date: string) {
        this.lastMainteanceAircon = !isEmpty(date) ? parseISO(date) : null;
    }

    isHURequired(dayRange = 30) {
        return (
            (isFuture(this.nextHUDate) || isThisMonth(this.nextHUDate)) &&
            isBefore(this.nextHUDate, endOfDay(addDays(new Date(), dayRange)))
        );
    }

    isFirstAidKitExpired() {
        return isPast(this.firstAidKitExpiry);
    }

    get messages(): Message[] {
        if (!this._messages) {
            this.refreshMessages();
        }

        return this._messages;
    }

    refreshMessages(
        minSeverity: Severity = "info",
        options?: {
            huRequired: number;
        }
    ): Message[] {
        this._messages = [];

        const huRequired =
            options && options.huRequired ? options.huRequired : 30;

        if (
            this.isHURequired(huRequired) &&
            compareSeverity("warning", minSeverity) >= 0
        ) {
            this._messages.push(
                new Message({
                    severity: "warning",
                    message: "message.vehicle.hu.required",
                })
            );
        }

        if (
            this.isFirstAidKitExpired() &&
            compareSeverity("info", minSeverity) >= 0
        ) {
            this._messages.push(
                new Message({
                    severity: "info",
                    message: "message.vehicle.firstaidkit.expired",
                })
            );
        }

        if (this.driver && this.driver.hasBirthday) {
            this._messages.push(
                new Message({
                    severity: "info",
                    message: "message.vehicle.driver.birthday",
                })
            );
        }

        return this._messages;
    }

    mergeVehicleInfo(info: Vehicle): Vehicle {
        const merged = new Vehicle(this);
        merged.length = info.length;
        merged.width = info.width;
        merged.height = info.height;
        merged.buildYearStart = info.buildYearStart;
        merged.buildYearEnd = info.buildYearEnd;
        merged.maxSpeed = info.maxSpeed;
        merged.catType = info.catType;
        merged.powertrainType = info.powertrainType;
        merged.emissionClass = info.emissionClass;
        merged.emptyWeight = info.emptyWeight;
        merged.engine = new Engine(info.engine);
        merged.type = new VehicleType(info.type);

        return merged;
    }
}
