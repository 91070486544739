import { Branch } from "./branch.model";
import { ContactInfo } from "./contact-info.model";
import { Entity } from "./entity.model";
import { Permission } from "./permission.model";

export class User extends Entity {
    enabled: boolean;
    username: string;
    firstLogin: Date;
    lastLogin: Date;

    title: string;
    firstname: string;
    lastname: string;
    branch: Branch;
    code: string;
    contactInfo: ContactInfo;
    allowedBranches: Array<Branch>;
    roles: Array<string> = [];
    permissions: Array<Permission> = [];

    constructor(user?: Partial<User>) {
        super(user);

        if (user) {
            Object.assign(this, user);

            this.firstLogin = user.firstLogin
                ? new Date(user.firstLogin)
                : null;

            this.lastLogin = user.lastLogin ? new Date(user.lastLogin) : null;

            this.contactInfo = new ContactInfo(user.contactInfo);
            this.branch = new Branch(user.branch);

            this.allowedBranches = user.allowedBranches
                ? user.allowedBranches.map((branch) => new Branch(branch))
                : [];

            this.roles = [...(user.roles ? user.roles : [])];

            this.permissions = user.permissions
                ? user.permissions.map((perm) => new Permission(perm))
                : [];
        }

        // Default values.
        this.contactInfo = this.contactInfo || new ContactInfo();
        this.allowedBranches = this.allowedBranches || [];
        this.roles = this.roles || [];
        this.permissions = this.permissions || [];
    }

    getPermission(target: string, action: string): Permission {
        const permission = this.permissions.find(
            (perm) => perm.target === target && perm.action === action
        );

        return permission;
    }
}
