export function removeUnderscoreKeys(request: any) {
    if (request == null) {
        return null;
    }

    Object.keys(request).forEach((key) => {
        const value = request[key];

        if (Array.isArray(value)) {
            value.forEach((item) => removeUnderscoreKeys(item));
        }

        if (key.startsWith("_")) {
            delete request[key];
            request[key.substr(1)] = value;
        }

        if (typeof value === "object") {
            removeUnderscoreKeys(value);
        }
    });

    return request;
}

export function removeKeys(keyNames: string | string[], request: any) {
    if (request == null) {
        return null;
    }

    if (!Array.isArray(keyNames)) {
        keyNames = [keyNames];
    }

    Object.keys(request).forEach((key) => {
        if (keyNames.indexOf(key) >= 0) {
            delete request[key];
        } else {
            const value = request[key];

            if (Array.isArray(value)) {
                value.forEach((item) => removeKeys(keyNames, value));
            } else if (typeof value === "object") {
                removeKeys(keyNames, value);
            }
        }
    });

    return request;
}
