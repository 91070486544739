export type InsuranceCaseType =
    | "LegalLiability"
    | "CrossLiability"
    | "FullyComprehensive"
    | "PartiallyComprehensive";

export class InsuranceCase {
    paymentConfirmed: boolean;
    entitledToDeductPreTax: boolean;
    type: InsuranceCaseType;
    deductible: number;

    constructor(iCase?: Partial<InsuranceCase>) {
        if (iCase) {
            Object.assign(this, iCase);
        }
    }
}
