import { Branch } from "./branch.model";
import { Entity } from "./entity.model";

export class Resource extends Entity {
    name: string;
    description: string;
    resourceCount: number;
    disabled: boolean;
    branch: Branch;
    view: string;
    includeInWorkload: boolean;

    constructor(resource?: Partial<Resource>) {
        super(resource);

        if (resource) {
            Object.assign(this, resource);
            this.branch = new Branch(resource.branch);
        }
    }
}
