import { Injectable } from "@angular/core";
import { ApiConfig } from "../models/api-config.model";

@Injectable({
    providedIn: "root"
})
export class ApiConfigService {
    private configs: any = {};

    set(config: ApiConfig) {
        const name = config.name || "default";
        this.configs[name] = config;
    }

    config(name = "default"): ApiConfig {
        return this.configs[name];
    }

    all(): ApiConfig[] {
        return Object.keys(this.configs).map(key => this.configs[key]);
    }
}
