import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { LocaleConfigService } from "@tcn/ngx-common";

declare var window: any;
declare var navigator: any;

@Injectable({
    providedIn: "root",
})
export class LocalizationService extends LocaleConfigService {
    constructor(private translate: TranslateService) {
        super();
    }

    initializeLanguages(
        languageDefinitions: Array<any>,
        defaultLanguage?: string
    ) {
        languageDefinitions.forEach((language) => {
            this.translate.addLangs([language.lang]);
            this.translate.setTranslation(language.lang, language.data, true);
        });

        this.setDefaultLanguage(defaultLanguage);
    }

    setActiveLanguage(lang: string) {
        super.setActiveLanguage(lang);

        this.translate.use(
            this.isLanguageSupported(lang) ? lang : this.defaultLang
        );
    }

    setDefaultLanguage(lang: string) {
        super.setDefaultLanguage(lang);
        this.translate.setDefaultLang(lang);
    }

    isLanguageSupported(lang: string, exact = false) {
        lang = lang.toLocaleLowerCase();

        for (let supportedLang of this.translate.getLangs()) {
            supportedLang = supportedLang.toLowerCase();

            if (supportedLang.startsWith(lang)) {
                if (exact && supportedLang.length !== lang.length) {
                    return false;
                }

                return true;
            }
        }

        return false;
    }

    async getPreferredLanguage(): Promise<string> {
        if (window.Intl && typeof window.Intl === "object") {
            return Promise.resolve(navigator.language);
        }

        return Promise.resolve("en");
    }
}
