export class BankInfo {
    mandateReference: string;
    accountNumber: string;
    mandateCreation: Date;
    directDebitAuthority: boolean;
    bankCountryCode: string;
    accountOwner: string;
    bankName: string;
    iban: string;
    bic: string;
    bankCode: string;
    swift: string;
    rib: string;

    constructor(bankInfo?: Partial<BankInfo>) {
        if (bankInfo) {
            Object.assign(this, bankInfo);

            this.mandateCreation = bankInfo.mandateCreation
                ? new Date(bankInfo.mandateCreation)
                : null;
        }
    }
}
