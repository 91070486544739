import { Pipe, PipeTransform } from "@angular/core";
import { ApiConfigService } from "../../services/api-config.service";
import { ApiConfig } from "../../models/api-config.model";

@Pipe({
    name: "apiUrl"
})
export class ApiUrlPipe implements PipeTransform {
    constructor(private apiConfig: ApiConfigService) {}

    transform(url: any) {
        if (!url) {
            return url;
        }

        const configs: ApiConfig[] = this.apiConfig.all();
        for (const config of configs) {
            url = config.parseUrl(url);
        }

        return url;
    }
}
