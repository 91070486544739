import { GLOBAL_STORE_CONFIG_OPTIONS } from "../store-options";

export function Reduce(actionType?: string | string[]): PropertyDecorator {
    return function decorate(
        target: Object,
        key: string,
        descriptor?: PropertyDescriptor
    ): PropertyDescriptor {
        if (actionType == null) {
            if (key.startsWith("_")) {
                actionType = [key.substr(1)];
            } else {
                throw Error(
                    "Reducers methods without specific action type must start with an underscore."
                );
            }
        }

        if (!Array.isArray(actionType)) {
            actionType = [actionType];
        }

        actionType = actionType.map(type => {
            return typeof target[type] === "function"
                ? (target.constructor.name + "_" + type).toUpperCase()
                : type;
        });

        let originalMethod: Function;

        const wrapped = function(this: any, ...args: any[]) {
            const state = args[0];
            const action = args[1];
            const processAction =
                actionType === null || actionType.indexOf(action.type) >= 0;

            if (processAction) {
                return originalMethod.apply(this, args);
            } else {
                return state;
            }
        };

        GLOBAL_STORE_CONFIG_OPTIONS.reducers.push({
            target: target.constructor,
            method: wrapped
        });

        descriptor = descriptor || Object.getOwnPropertyDescriptor(target, key);
        if (descriptor === undefined) {
            const dispatchDescriptor: PropertyDescriptor = {
                get: () => wrapped,
                set: setMethod => (originalMethod = setMethod)
            };

            Object.defineProperty(target, key, dispatchDescriptor);
            return dispatchDescriptor;
        } else {
            originalMethod = descriptor.value;
            descriptor.value = wrapped;
            return descriptor;
        }
    };
}
