import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { Branch } from "../models/branch.model";
import { User } from "../models/user.model";
import { Processor } from "./../../bookings/models/processor.model";
import { Resource } from "./../models/resource.model";
import { Service } from "./../models/service.model";

@Injectable({
    providedIn: "root"
})
export class ConfigService {
    constructor(protected http: HttpClient) {}

    getBranches(): Observable<Branch[]> {
        return this.http
            .get<Branch[]>("neonis.api.config.v1/branches")
            .pipe(map(response => response.map(branch => new Branch(branch))));
    }

    getUsers(): Observable<User[]> {
        return this.http
            .get<User[]>("neonis.api.config.v1/users")
            .pipe(map(response => response.map(user => new User(user))));
    }

    getServices(options?: {
        branch?: string;
        withBranch?: boolean;
    }): Observable<Service[]> {
        const params = new HttpParams();

        if (options && options.branch) {
            params.set("branch", options.branch);
        }

        if (options && options.withBranch) {
            params.set("withBranch", options.withBranch.toString());
        }

        return this.http
            .get<Service[]>("neonis.api.config.v1/services")
            .pipe(
                map(response => response.map(service => new Service(service)))
            );
    }

    getProcessors(options?: {
        branch?: string;
        withBranch?: boolean;
    }): Observable<Processor[]> {
        const params = new HttpParams();

        if (options && options.branch) {
            params.set("branch", options.branch);
        }

        if (options && options.withBranch) {
            params.set("withBranch", options.withBranch.toString());
        }

        return this.http
            .get<Processor[]>("neonis.api.config.v1/processors")
            .pipe(
                map(response =>
                    response.map(processor => new Processor(processor))
                )
            );
    }

    getResources(options?: {
        branch?: string;
        withBranch?: boolean;
    }): Observable<Resource[]> {
        const params = new HttpParams();

        if (options && options.branch) {
            params.set("branch", options.branch);
        }

        if (options && options.withBranch) {
            params.set("withBranch", options.withBranch.toString());
        }

        return this.http
            .get<Resource[]>("neonis.api.config.v1/resources")
            .pipe(
                map(response =>
                    response.map(processor => new Resource(processor))
                )
            );
    }
}
