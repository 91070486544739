import { UUID } from "@tcn/ngx-common";
import { Image } from "../../documents/models/image.model";
import { Document } from "../../documents/models/document.model";
import { Vehicle } from "./vehicle.model";

export class VehicleImage extends Image {
    static readonly Category = "image.vehicle";

    constructor(image?: Partial<Document | Image | VehicleImage>) {
        super(image);

        // Default values
        this.category = this.category || VehicleImage.Category;
    }

    set vehicle(vehicle: Vehicle) {
        if (vehicle != null) {
            this.directory = `/vehicles/${vehicle.uuid}/`;
        } else {
            this.directory = null;
        }

        this.filename = this.filename || UUID.new();
        this.path = this.directory + this.filename;
    }
}
