import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { AlertController } from "@ionic/angular";
import { AlertOptions } from "@ionic/core";

@Injectable({
    providedIn: "root"
})
export class LocalizedAlertController {
    constructor(
        private alertCtrl: AlertController,
        private translate: TranslateService
    ) {}

    create(
        options: Partial<AlertOptions> = {},
        interpolateParams?: Object
    ): Promise<any> {
        const requiredTranslations: Array<any> = [];

        if (options.header) {
            requiredTranslations.push(options.header);
        }

        if (options.subHeader) {
            requiredTranslations.push(options.subHeader);
        }

        if (options.message) {
            requiredTranslations.push(options.message);
        }

        if (options.buttons) {
            for (let i = 0; i < options.buttons.length; ++i) {
                const button: any = options.buttons[i];
                if (button.text) {
                    requiredTranslations.push(button.text);
                }
            }
        }

        if (options.inputs) {
            for (let i = 0; i < options.inputs.length; ++i) {
                const input: any = options.inputs[i];
                if (input.placeholder) {
                    requiredTranslations.push(input.placeholder);
                }

                if (input.label) {
                    requiredTranslations.push(input.label);
                }
            }
        }

        return new Promise((resolve, reject) => {
            this.translate
                .get(requiredTranslations, interpolateParams)
                .subscribe(
                    translations => {
                        options = Object.assign({}, options);
                        options.header = translations[options.header || ""];
                        options.message = options.message ? translations[String(options.message)] : '';

                        if (options.buttons) {
                            for (let i = 0; i < options.buttons.length; ++i) {
                                const button: any = options.buttons[i];
                                button.text = translations[button.text];
                            }
                        }

                        if (options.inputs) {
                            for (let i = 0; i < options.inputs.length; ++i) {
                                const input: any = options.inputs[i];
                                input.placeholder =
                                    translations[input.placeholder];
                                input.label = translations[input.label];
                            }
                        }

                        resolve(this.alertCtrl.create(options));
                    },
                    error => {
                        reject(error);
                    }
                );
        });
    }
}
