import { Observable } from "rxjs";
import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map } from "rxjs/operators";

import { Query } from "../../core/models/query.model";
import { QueryResponse } from "../../core/models/query-response.model";
import { Document } from "../models/document.model";

export class DocumentQuery extends Query {
    identity: string;
    name: string;
    directory: string;
    file: string;
    path: string;
    category: string;

    constructor(query?: Partial<DocumentQuery>) {
        super(query);

        if (query) {
            Object.assign(this, query);
        }

        this.sortBy = this.sortBy || "name";
        this.sortType = this.sortType || "asc";
    }

    get empty(): boolean {
        const empty = !this.name && !this.directory && !this.file;
        return empty;
    }
}

export type WithContentOption = "bytes" | "base64" | "raw";

@Injectable({ providedIn: "root" })
export class DocumentsService {
    constructor(protected http: HttpClient) {}

    queryDocuments(
        query: DocumentQuery,
        withContent?: WithContentOption
    ): Observable<QueryResponse<Document>> {
        let params = new HttpParams();
        if (withContent) {
            params = params.append("withContent", withContent);
        }

        return this.http
            .post("neonis.api.documents.v1/query", query, { params })
            .pipe(
                map(
                    (response: any) =>
                        new QueryResponse<Document>({
                            ...response,
                            data: response.data.map(doc => new Document(doc))
                        })
                )
            );
    }

    getDocument(
        docId: string,
        withContent?: WithContentOption
    ): Observable<Document> {
        let params = new HttpParams();
        if (withContent) {
            params = params.append("withContent", withContent);
        }

        return this.http
            .get(`neonis.api.documents.v1/${docId}`, { params })
            .pipe(map(doc => new Document(doc)));
    }

    saveDocument(doc: Document, withContent?: "base64"): Observable<Document> {
        let params = new HttpParams();
        if (withContent) {
            params = params.append("withContent", withContent);
        }

        const save$ =
            doc.id != null
                ? this.http.post(`neonis.api.documents.v1/${doc.id}`, doc, {
                      params
                  })
                : this.http.put("neonis.api.documents.v1", doc, { params });

        return save$.pipe(map(response => new Document(response)));
    }

    deleteDocument(docId: string): Observable<any> {
        return this.http.delete(`neonis.api.documents.v1/${docId}`);
    }
}
