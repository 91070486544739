import { Branch } from "./branch.model";
import { Entity } from "./entity.model";

export class Service extends Entity {
    name: string;
    description: string;
    disabled: boolean;
    color: string;
    branch: Branch;
    initialTime: number;

    price: number;
    oldPrice: number;
    priceCurrency: string;
    priceNote: string;
    image: string;

    constructor(service: Partial<Service>) {
        super(service);

        if (service) {
            Object.assign(this, service);
            this.branch = new Branch(service.branch);
        }
    }
}
