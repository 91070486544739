import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable({ providedIn: "root" })
export class SettingsService {
    constructor(protected http: HttpClient) {}

    getSettings(type: string, scope: string): Observable<any> {
        const url = `neonis.api.settings.v1/${type}/${scope}`;
        return this.http.get<any>(url).pipe(
            map(settings => ({
                ...settings,
                value: JSON.parse(settings.value)
            }))
        );
    }

    setSettings(settings: any, type: string, scope: string): Observable<any> {
        const url = `neonis.api.settings.v1/${type}/${scope}`;
        return this.http.post(url, settings).pipe(map(response => settings));
    }
}
