import { Entity } from "./../../core/models/entity.model";

export class StorageService extends Entity {
    name: string;
    finishedDate: Date;
    appointmentDate: Date;
    articlePrice: number;
    articleNumber: string;

    constructor(service: Partial<StorageService>) {
        super(service);

        if (service) {
            Object.assign(this, service);

            this.finishedDate = service.finishedDate
                ? new Date(service.finishedDate)
                : null;

            this.appointmentDate = service.appointmentDate
                ? new Date(service.appointmentDate)
                : null;
        }
    }
}
