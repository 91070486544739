import { UUID } from "@tcn/ngx-common";
import { WheelStorage } from "./wheelstorage.model";
import { Image } from "../../documents/models/image.model";
import { Document } from "../../documents/models/document.model";
import { WheelPosition } from "./stored-wheel.model";

export class WheelImage extends Image {
    static readonly Category = "image.storage";

    constructor(image?: Partial<Document | Image | WheelImage>) {
        super(image);

        // Default values
        this.category = this.category || WheelImage.Category;
        this.meta.wheel = this.meta.wheel || {};

        if (!("position" in this.meta.wheel)) {
            this.meta.wheel.position = null;
        }
    }

    get position(): WheelPosition {
        return this.meta.wheel.position;
    }

    set position(position: WheelPosition) {
        this.meta.wheel.position = position;
    }

    set storage(storage: WheelStorage) {
        if (storage != null) {
            this.directory = `/storages/${storage.uuid}/`;
        } else {
            this.directory = null;
        }

        this.filename = this.filename || UUID.new();
        this.path = this.directory + this.filename;
    }
}
