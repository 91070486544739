export class ContactInfo {
    title: string;
    firstName: string;
    lastName: string;
    email: string;
    homepage: string;
    mobile: string;
    fax: string;
    phone: string;

    constructor(contactInfo?: Partial<ContactInfo>) {
        if (contactInfo) {
            Object.assign(this, contactInfo);
        }
    }
}
