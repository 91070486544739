import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "abs"
})
export class AbsPipe implements PipeTransform {
    transform(val: number): number {
        return Math.abs(val);
    }
}
