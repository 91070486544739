export interface StoreOptions {
    withLogger: boolean;
    rootReducer: any;
    initState: any;
    middleware: Array<any>;
    enhancers: Array<any>;
    epics: Array<any>;
    reducers: Array<any>;
    autoRun: Array<string>;
}

export const GLOBAL_STORE_CONFIG_OPTIONS: StoreOptions = {
    withLogger: false,
    rootReducer: null,
    initState: null,
    middleware: [],
    enhancers: [],
    epics: [],
    reducers: [],
    autoRun: []
};
