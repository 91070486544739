import { ApiConfig } from "@tcn/ngx-common";

export class NeonisApiConfig extends ApiConfig {
    constructor(config?: Partial<ApiConfig>) {
        super({
            name: "neonis.api.v1",
            scheme: "https",
            host: "www",
            domain: "myneonis.de",
            port: 80,
            baseUrl: "/mitarbeiter/seam/resource/rest",

            headers: [
                {
                    name: "Content-Type",
                    value: "application/json",
                },
                {
                    name: "Accept",
                    value: "application/json",
                },
                {
                    name: "NEO-API-VERSION",
                    value: "2.0",
                },
            ],

            auth: {
                token: {
                    header: "NEO-API-KEY",
                    value: "",
                },
            },

            mappings: [
                {
                    name: "neonis.api.auth.v1",
                    path: `/auth`,
                },
                {
                    name: "neonis.api.config.v1",
                    path: `/config`,
                },
                {
                    name: "neonis.api.customers.v1",
                    path: `/data/customer`,
                },
                {
                    name: "neonis.api.vehicles.v1",
                    path: `/data/vehicle`,
                },
                {
                    name: "neonis.api.vehicles.info.v1",
                    path: `/vehicle`,
                },
                {
                    name: "neonis.api.wheelstorages.v1",
                    path: `/data/storage`,
                },
                {
                    name: "neonis.api.bookings.v1",
                    path: `/data/booking`,
                },
                {
                    name: "neonis.api.documents.v1",
                    path: `/data/documents`,
                },
                {
                    name: "neonis.api.vouchers.v1",
                    path: `/data/vouchers`,
                },
                {
                    name: "neonis.api.tags.v1",
                    path: `/data/tags`,
                },
            ],
        });

        this.assign(config);
    }
}
