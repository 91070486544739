import {
    Storable,
    StorableState,
    storableStateIndex,
    storableStateValue,
} from "./storable.model";
import { StoredRim } from "./stored-rim.model";
import { StoredTyre } from "./stored-tyre.model";

export type WheelPosition =
    | "FRONT_LEFT"
    | "FRONT_RIGHT"
    | "REAR_LEFT"
    | "REAR_RIGHT"
    | "SPARE";

export class StoredWheel extends Storable {
    position: WheelPosition;
    tyre: StoredTyre;
    rim: StoredRim;
    sensorId: string;

    constructor(wheel?: Partial<StoredWheel>) {
        super(wheel);

        if (wheel) {
            Object.assign(this, wheel);

            this.tyre = new StoredTyre(wheel.tyre);
            this.rim = new StoredRim(wheel.rim);
        }

        // Default values
        this.tyre = this.tyre || new StoredTyre();
        this.rim = this.rim || new StoredRim();
    }

    get state(): StorableState {
        const maxIndex = Math.max(
            this.tyre ? storableStateIndex(this.tyre.state) : 0,
            this.rim ? storableStateIndex(this.rim.state) : 0
        );

        return storableStateValue(maxIndex);
    }
}
