import { isEmpty } from "@tcn/ngx-common";
import { formatISO, getDate, getMonth, isSameDay, parseISO } from "date-fns";

import { Address } from "../../core/models/address.model";
import { BankInfo } from "../../core/models/bank-info.model";
import { ContactInfo } from "../../core/models/contact-info.model";
import { DataField } from "../../core/models/datafield.model";
import { Entity } from "../../core/models/entity.model";
import { Tag, tagsFromString } from "../../tags/models/tag.model";
import { Message, Severity } from "./../../core/models/message.model";
import { ContactPerson } from "./contact-person.model";
import { CustomerOptions } from "./customer-options.model";

export class Customer extends Entity {
    static readonly TagTarget = "customer";

    refId: string;
    refId2: string;
    refId3: string;

    createdOn: Date;
    createdBy: string;
    updatedOn: Date;
    updatedBy: string;
    syncedOn: Date;

    customerNumber: string;
    company: string;
    title: string;
    name: string;
    firstName: string;
    lastName: string;
    tags: string;
    source: string;
    note: string;
    tiein: string;
    dealer: boolean;

    privacyStatementAccepted: boolean;
    privacyStatementDate: Date;
    address: Address;
    birthday: Date;
    contactInfo: ContactInfo;
    contactPersons: Array<ContactPerson>;
    bankInfo: BankInfo;
    dataFields: Array<DataField>;
    options: CustomerOptions;

    private _messages: Message[];

    constructor(customer?: Partial<Customer>) {
        super(customer);

        if (customer) {
            Object.assign(this, customer);

            this.createdOn = customer.createdOn
                ? new Date(customer.createdOn)
                : null;

            this.updatedOn = customer.updatedOn
                ? new Date(customer.updatedOn)
                : null;

            this.syncedOn = customer.syncedOn
                ? new Date(customer.syncedOn)
                : null;

            this.address = new Address(customer.address);
            this.contactInfo = new ContactInfo(customer.contactInfo);

            this.privacyStatementDate = customer.privacyStatementDate
                ? new Date(customer.privacyStatementDate)
                : null;

            this.birthday = customer.birthday
                ? new Date(customer.birthday)
                : null;

            this.contactPersons = customer.contactPersons
                ? customer.contactPersons.map(
                      (person) => new ContactPerson(person)
                  )
                : [];

            this.bankInfo = new BankInfo(customer.bankInfo);

            this.dataFields = customer.dataFields
                ? customer.dataFields.map((field) => new DataField(field))
                : [];

            this.options = customer.options
                ? new CustomerOptions(customer.options)
                : null;
        }

        // Default values.
        this.address = this.address || new Address();
        this.contactInfo = this.contactInfo || new ContactInfo();
        this.bankInfo = this.bankInfo || new BankInfo();
        this.contactPersons = this.contactPersons || [];
        this.dataFields = this.dataFields || [];
    }

    get birthdayISO(): string {
        return this.birthday ? formatISO(this.birthday) : null;
    }

    set birthdayISO(date: string) {
        this.birthday = !isEmpty(date) ? parseISO(date) : null;
    }

    get hasBirthday(): boolean {
        const currentDate = new Date();

        return (
            this.birthday != null &&
            getMonth(currentDate) === getMonth(this.birthday) &&
            getDate(currentDate) === getDate(this.birthday)
        );
    }

    get messages(): Message[] {
        if (!this._messages) {
            this.refreshMessages();
        }

        return this._messages;
    }

    refreshMessages(
        minSeverity: Severity = "info",
        options?: {
            huRequired: number;
        }
    ): Message[] {
        this._messages = [];

        if (this.birthday && isSameDay(new Date(), this.birthday)) {
            this._messages.push(
                new Message({
                    severity: "info",
                    message: "message.customer.birthday",
                })
            );
        }

        return this._messages;
    }
}
