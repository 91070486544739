import { Entity } from "./entity.model";
import { Address } from "./address.model";
import { ContactInfo } from "./contact-info.model";

export class Tenant extends Entity {
    created: Date;
    lastUpdated: Date;
    lastLogin: Date;
    demoExpiry: Date;

    enabled: boolean;
    apiKey: string;
    theme: string;
    baseConfigured: boolean;

    company: string;
    title: string;
    firstname: string;
    lastname: string;
    domain: string;
    customerId: string;
    referenceId: string;
    address: Address;
    contactInfo: ContactInfo;

    constructor(tenant?: Partial<Tenant>) {
        super(tenant);

        if (tenant) {
            Object.assign(this, tenant);
            this.address = new Address(tenant.address);
            this.contactInfo = new ContactInfo(tenant.contactInfo);
        }

        // Default values.
        this.address = this.address || new Address();
        this.contactInfo = this.contactInfo || new ContactInfo();
    }
}
