import { isEmpty } from "@tcn/ngx-common";
import { formatISO, getDate, getMonth, isSameDay, parseISO } from "date-fns";

import { Address } from "../../core/models/address.model";
import { BankInfo } from "../../core/models/bank-info.model";
import { ContactInfo } from "../../core/models/contact-info.model";
import { DataField } from "../../core/models/datafield.model";
import { Entity } from "../../core/models/entity.model";
import { Tag, tagsFromString } from "../../tags/models/tag.model";
import { Message, Severity } from "../../core/models/message.model";
import { ContactPerson } from "./contact-person.model";

export class CustomerOptions extends Entity {
    username: string;
    password: string;

    onlyDriverVehicles: boolean;
    denyCustomerBookings: boolean;

    lastLogin: Date;
    firstLogin: boolean;

    acceptedPrivacyPolicy: boolean;
    acceptedGeneralBusinesTerms: boolean;

    // Options for appointment messages
    sendReminderToCustomer: boolean = true;
    sendCancellationToCustomer: boolean = true;
    sendConfirmationToCustomer: boolean = true;
    sendAcceptionToCustomer: boolean = true;
    sendRejectionToCustomer: boolean = true;

    sendReminderToDriver: boolean;
    sendCancellationToDriver: boolean;
    sendConfirmationToDriver: boolean;
    sendAcceptionToDriver: boolean = true;
    sendRejectionToDriver: boolean = true;

    constructor(customer?: Partial<CustomerOptions>) {
        super(customer);

        if (customer) {
            Object.assign(this, customer);

            this.lastLogin = customer.lastLogin
                ? new Date(customer.lastLogin)
                : null;
        }
    }
}
