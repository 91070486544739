import { Document } from "../../documents/models/document.model";
import { Image } from "../../documents/models/image.model";
import { Vehicle } from "./vehicle.model";

export class FleetCardImage extends Image {
    static readonly Category = "image.vehicle.fleetcard";

    constructor(image?: Partial<Document | Image | FleetCardImage>) {
        super(image);

        // Default values
        this.category = this.category || FleetCardImage.Category;

        if (!("side" in this.meta)) {
            this.meta.side = null;
        }
    }

    get side(): "front" | "back" {
        return this.meta.side;
    }

    set side(side: "front" | "back") {
        this.meta = this.meta || {};
        this.meta.side = side;
        this.filename = side;
        this.path = this.directory + side;
    }

    set vehicle(vehicle: Vehicle) {
        if (vehicle != null) {
            this.directory = `/vehicles/${vehicle.uuid}/fleetcard/`;
        } else {
            this.directory = null;
        }

        this.path = this.directory + this.filename;
    }
}
