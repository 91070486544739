import { OnDestroy } from "@angular/core";
import { Subject } from "rxjs";

export class ComponentController implements OnDestroy {
    protected destroyed$ = new Subject();

    ngOnDestroy(): void {
        this.destroyed$.next();
        this.destroyed$.complete();
    }
}
