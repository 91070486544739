import { Entity } from "../../core/models/entity.model";

export type StorableState = "UNKNOWN" | "OKAY" | "MARGINAL" | "RISKY";

export function storableStateIndex(state: StorableState): number {
    if (state === "UNKNOWN") {
        return 0;
    }

    if (state === "OKAY") {
        return 1;
    }

    if (state === "MARGINAL") {
        return 2;
    }

    if (state === "RISKY") {
        return 3;
    }

    return 0;
}

export function storableStateValue(index: number): StorableState {
    if (index === 0) {
        return "UNKNOWN";
    }

    if (index === 1) {
        return "OKAY";
    }

    if (index === 2) {
        return "MARGINAL";
    }

    if (index === 3) {
        return "RISKY";
    }

    return "UNKNOWN";
}

export class Storable extends Entity {
    comment: string;
    state: StorableState;
    storageLocation: string;

    constructor(storable?: Partial<Storable>) {
        super(storable);
    }
}
