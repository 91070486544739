export class ApiConfig {
    name?: string;
    scheme = "https";
    host: string;
    domain: string;
    port = 80;
    baseUrl?: string;
    disabledFor: string[];

    auth?: {
        token?: {
            header: string;
            value: string;
        };

        basic?: {
            username: string;
            password: string;
        };
    } = {};

    mappings?: Array<{
        name: string;
        path: string;
    }> = [];

    headers?: Array<{
        name: string;
        value: string;
    }> = [];

    constructor(config?: Partial<ApiConfig>) {
        this.assign(config);
    }

    assign(config?: Partial<ApiConfig>): ApiConfig {
        if (config) {
            Object.assign(this, config);
        }

        return this;
    }

    enabledForUrl(url: string): boolean {
        const hostAndDomain = `${this.host}.${this.domain}`;

        if (this.disabledFor) {
            for (const keyword of this.disabledFor) {
                if (url.indexOf(keyword) >= 0) {
                    return false;
                }
            }
        }

        for (const api of this.mappings) {
            if (url.indexOf(api.name) >= 0 || url.indexOf(hostAndDomain) >= 0) {
                return true;
            }
        }

        return false;
    }

    parseUrl(url: string): string {
        for (const api of this.mappings) {
            if (url.indexOf(api.name) >= 0) {
                url = `/${this.baseUrl}/${url.replace(api.name, api.path)}`;
                url = url.replace(/\/\/+/g, "/");
                url = `${this.scheme}://${this.host}.${this.domain}:${this.port}${url}`;

                return url;
            }
        }

        return url;
    }
}
