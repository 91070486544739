import { Entity } from "../../core/models/entity.model";

export enum VehicleCheckState {
    OKAY,
    MARGINAL,
    RISKY
}

export class VehicleCheck extends Entity {
    state: VehicleCheckState;
    reviser: string;
    description: string;
    checkedDate: Date;
    names: string;

    categoryUUID: string;
    categoryName: string;

    constructor(check?: Partial<VehicleCheck>) {
        super(check);

        if (check) {
            Object.assign(this, check);

            this.checkedDate = check.checkedDate
                ? new Date(check.checkedDate)
                : null;
        }
    }
}
