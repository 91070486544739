import { FormsModule } from "@angular/forms";
import { NgModule } from "@angular/core";
import { LineSeparatorComponent } from "./line-separator/line-separator";
import { TranslateModule } from "@ngx-translate/core";
import { ItemsSearchComponent } from "./items-search/items-search";
import { CommonModule } from "@angular/common";
import { IonicModule } from "@ionic/angular";

@NgModule({
    declarations: [LineSeparatorComponent, ItemsSearchComponent],
    imports: [
        CommonModule,
        FormsModule,
        IonicModule,
        TranslateModule.forChild()
    ],
    exports: [LineSeparatorComponent, ItemsSearchComponent]
})
export class IonicCommonComponentsModule {}
