import { join, isEmpty } from "@tcn/ngx-common";
export class VehicleType {
    manufacturerCode: string;
    typeCode: string;
    versionCode: string;
    typeAndVersionCode: string;

    manufacturerName: string;
    typeName: string;
    versionName: string;
    modelName: string;

    identification: string;

    constructor(type?: Partial<VehicleType>) {
        if (type) {
            Object.assign(this, type);

            if (isEmpty(this.identification)) {
                this.identification = join([
                    this.manufacturerName,
                    this.modelName,
                    this.versionName,
                    this.typeName,
                ]);
            }
        }
    }

    get code(): string {
        return `${this.manufacturerCode || "---"}/${
            this.typeAndVersionCode || "--"
        }`;
    }
}
