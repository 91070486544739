import { Observable, Subject } from "rxjs";

export interface BlockUntilFinishedOptions {
    delay?: number;
    state?: string;
}

export function BlockUntilFinished(
    options?: BlockUntilFinishedOptions
): PropertyDecorator {
    options = options || { delay: 0 };
    options.delay = options.delay || 0;

    return function decorate(
        target: Object,
        key: string | symbol | number,
        descriptor?: PropertyDescriptor
    ): PropertyDescriptor {
        let originalMethod: Function;
        let blocked = false;

        const updateState = self => {
            if (options.state) {
                self[options.state] = blocked;
            }
        };

        const unblock = self => {
            setTimeout(() => {
                blocked = false;
                updateState(self);
            }, options.delay);
        };

        updateState(target);

        const wrapped = function(this: any, ...args: any[]) {
            if (blocked) {
                return;
            }

            blocked = true;
            updateState(this);

            const response = originalMethod.apply(this, args);
            if (response == null) {
                unblock(this);
                return;
            }

            const isPromise = typeof response.then === "function";
            if (isPromise) {
                return new Promise((resolve, reject) => {
                    response.then(
                        (result: any) => {
                            unblock(this);
                            resolve(result);
                        },
                        (error: any) => {
                            unblock(this);
                            reject(error);
                        }
                    );
                });
            }

            const isObservable = response instanceof Observable;
            if (isObservable) {
                const subject = new Subject();
                const subscription = response.subscribe(
                    (result: any) => {
                        subject.next(result);
                    },
                    (error: Error) => {
                        unblock(this);

                        subject.error(error);
                    },
                    () => {
                        unblock(this);

                        subject.complete();
                        subscription.unsubscribe();
                    }
                );

                return subject.asObservable();
            }

            return response;
        };

        descriptor = descriptor || Object.getOwnPropertyDescriptor(target, key);
        if (descriptor === undefined) {
            const dispatchDescriptor: PropertyDescriptor = {
                get: () => wrapped,
                set: setMethod => (originalMethod = setMethod)
            };

            Object.defineProperty(target, key, dispatchDescriptor);
            return dispatchDescriptor;
        } else {
            originalMethod = descriptor.value;
            descriptor.value = wrapped;
            return descriptor;
        }
    };
}
