import { formatCurrency, getCurrencySymbol } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { LocaleConfigService } from "../../services/locale-config.service";

@Pipe({
    name: "localeCurrency"
})
export class LocaleCurrencyPipe implements PipeTransform {
    constructor(protected localeConfig: LocaleConfigService) {}

    transform(value: any, currencyCode: string, digitsInfo = ".2-2") {
        if (value == null) {
            return "";
        }

        const lang = this.localeConfig.getActiveLanguage();

        return formatCurrency(
            value,
            lang,
            getCurrencySymbol(currencyCode, "narrow", lang),
            currencyCode,
            digitsInfo
        );
    }
}
