import { UUID } from "@tcn/ngx-common";

export class Entity {
    id: string;
    uuid: string;
    refId: string;
    version: number;
    deleted: boolean;

    static removeFromList<T extends Entity>(
        entity: T,
        list: Array<T>
    ): Array<T> {
        if (!entity || !list) {
            return list;
        }

        return list.filter($ => $.id !== entity.id);
    }

    static replaceInList<T extends Entity>(
        entity: T,
        list: Array<T>
    ): Array<T> {
        if (!entity || !list) {
            return list;
        }

        return list.map($ => ($.id === entity.id ? entity : $));
    }

    static replaceOrAddInList<T extends Entity>(
        entity: T,
        list: Array<T>
    ): Array<T> {
        if (Entity.existsInList(entity, list)) {
            return Entity.replaceInList(entity, list);
        } else {
            return [...list, entity];
        }
    }

    static existsInList<T extends Entity>(entity: T, list: Array<T>): boolean {
        if (!entity || !list) {
            return false;
        }

        return list.find($ => $.id === entity.id) != null;
    }

    constructor(entity?: Partial<Entity>) {
        if (entity) {
            Object.assign(this, entity);
        }

        // Default values
        this.uuid = this.uuid || UUID.new();
    }

    equals(other: Entity): boolean {
        return (
            other != null &&
            ((other.id != null ? other.id === this.id : false) ||
                (other.uuid != null ? other.uuid === this.uuid : false) ||
                (other.refId != null ? other.refId === this.refId : false))
        );
    }

    get persisted(): boolean {
        return this.id != null;
    }

    set persisted(value: boolean) {
        // ignored
    }
}
