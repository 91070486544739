/**
 * @constructor
 * Item constructor.
 *
 * @param [values]
 * Value of the item.
 *
 * @param [subValuesProperty]
 * Name of a property that holds a list of values that should be used for sub items.
 *
 * @param [parent]
 * The parent item.
 *
 * @return {Object}
 *
 *      id:
 *      Unique id of the item.
 *
 *      selected:
 *      True when the item is selected, otherwise false.
 *
 *      disabled:
 *      True when the item is disabled, otherwise false.
 *
 *      value:
 *      The original value, where this item is created from.
 *
 *      subItems:
 *      List of sub items.
 *
 *      source:
 *      The items object this item belongs to.
 *
 *      parent:
 *      The parent items.
 *
 *      callback:
 *      Callback function that is called when the item is selected.
 */

export class Item {
    source?: Items;
    id = uuid();
    disabled = false;
    value: any;
    subItems: Array<Item> = [];
    _parent?: Item;
    _selected = false;
    _hidden = false;
    callback?: Function;
    data: any = {};
    autoSelectParentItem = true;
    autoSelectSubItems = true;

    constructor(
        value?: any,
        subValuesProperty?: string | null,
        parent?: Item,
        callback?: Function
    ) {
        this.id = uuid();
        this.disabled = false;
        this.hidden = false;
        this.value = value;
        this.subItems = [];
        this._parent = parent;
        this._selected = false;
        this._hidden = false;
        this.callback = callback;

        if (subValuesProperty != null) {
            const _self = this;

            value[subValuesProperty].forEach(function(subValue: any) {
                _self.subItems.push(new Item(subValue, null, _self, callback));
            });
        }
    }

    /**
     * Creates a copy of the item and groups sub items.
     *
     * @returns Copy of the item.
     */
    copy(): Item {
        const copy = new Item();
        copy.source = this.source;
        copy.id = this.id;
        copy.disabled = this.disabled;
        copy.value = this.value;
        copy._parent = this.parent;
        copy._selected = this._selected;
        copy._hidden = this._hidden;
        copy.callback = this.callback;

        copy.subItems = this.subItems.map(subItem => {
            return subItem.copy();
        });

        return copy;
    }

    get isParent() {
        return (
            this.parent == null || (this.subItems && this.subItems.length > 0)
        );
    }

    get hidden(): boolean {
        return this._hidden;
    }

    set hidden(hidden: boolean) {
        if (this.hidden !== hidden) {
            this._hidden = hidden;

            if (this.source) {
                this.source.visible = this.source.all.filter(
                    item => !item.hidden
                );
                this.source.hidden = this.source.all.filter(
                    item => item.hidden
                );
            }

            /*
            if (hidden) {
                this.source.hidden = [...this.source.hidden, this];
                this.source.visible = this.source.visible.filter(item => item.id != this.id);
            } else {
                this.source.visible = [...this.source.visible, this];
                this.source.hidden = this.source.hidden.filter(item => item.id != this.id);
            }
            */
        }
    }

    /**
     *  Add an item as sub item.
     *
     * @param item
     *  The item to add.
     *
     * @param asCopy
     *  When true a copy of the item is added instead of the original one.
     *
     * @returns
     *  The added item.
     */
    add(item: Item, asCopy: boolean = false): Item {
        if (asCopy) {
            item = item.copy();
        }

        this.subItems.push(item);
        item._parent = this;
        item.source = this.source;
        if (!item.callback) {
            item.callback = this.callback;
        }

        return item;
    }

    /**
     *  All groups items as sub items.
     *
     * @param items
     *  The items to add.
     *
     * @param asCopy
     *  When true a copy of the items is added instead of the original one.
     */
    addAll(items: Array<Item>, asCopy: boolean = false) {
        items.forEach(item => {
            this.add(item, asCopy);
        });
    }

    get parent(): Item | undefined {
        return this._parent;
    }

    set parent(parent) {
        const _self = this;
        _self.parent = parent;

        _self.subItems.forEach(function(subItem) {
            subItem.parent = parent;
        });
    }

    allSubItemsSelected(selected: boolean = true): boolean {
        if (!this.subItems || this.subItems.length === 0) {
            return false;
        }

        for (const subItem of this.subItems) {
            if (subItem.selected !== selected) {
                return false;
            }
        }

        return true;
    }

    get selected(): boolean {
        return this._selected;
    }

    /**
     * Selects or deselect the item.
     *
     * When the item has sub items all sub items are selected or deselected.
     * When the item has no sub items the parent item is selected when any
     * sub item is selected or deselected otherwise.
     *
     * @param selected
     * When true the item is selected, otherwise deselected.
     */

    set selected(selected) {
        const _self = this;

        if (_self.disabled || _self._selected === selected) {
            return;
        }

        if (_self.source && _self.source.unselectable === false) {
            if (_self._selected && !selected) {
                _self._selected = true;
                return;
            }
        }

        // Set all items to deselected when single selection is activated.
        if (_self.source && _self.source.singleSelection === true) {
            _self.source.all.forEach(function(item) {
                item._selected = false;

                item.subItems.forEach(function(subItem) {
                    subItem._selected = false;
                });
            });
        }

        _self._selected = selected;

        if (_self.subItems.length > 0) {
            if (_self.autoSelectSubItems) {
                _self.subItems.forEach(function(subItem) {
                    subItem._selected = _self._selected;
                });
            }
        } else {
            if (_self.parent && _self.parent.autoSelectParentItem) {
                const selectedSubItem = _self.parent.subItems.find(function(
                    subItem
                ) {
                    return subItem._selected;
                });

                _self.parent._selected = selectedSubItem != null;
            }
        }

        if (_self.source) {
            _self.source._selected = findSelectedItems(
                _self.source.all,
                true,
                true
            );
        }

        if (_self.callback != null) {
            _self.callback(_self);
        }
    }

    /**
     * Selects or deselect the item.
     *
     * @param selected
     * When true the item is selected, otherwise deselected.
     */
    select(selected: boolean = true) {
        this.selected = selected;
    }

    /**
     * Toggle the item selected.
     */
    toggle() {
        this.selected = !this.selected;
    }

    /**
     * Remove the item from its source list.
     */
    remove() {
        if (this.source) {
            this.source.remove(this);
        }
    }

    /**
     * Returns true when the item has sub items.
     */
    get hasSubItems(): boolean {
        return this.subItems.length > 0;
    }

    /**
     * Returns the first sub item.
     */
    get firstSubItem(): Item | null {
        return this.hasSubItems ? this.subItems[0] : null;
    }

    /**
     * Returns the last sub item.
     */
    get lastSubItem(): Item | null {
        return this.hasSubItems
            ? this.subItems[this.subItems.length - 1]
            : null;
    }
}

// tslint:disable-next-line:max-classes-per-file
export class Items {
    singleSelection = false;
    unselectable = true;

    all: Array<Item> = [];
    _selected: Array<Item> = [];
    visible: Array<Item> = [];
    hidden: Array<Item> = [];
    data: any = {};

    constructor(
        values?: Array<any>,
        subValuesProperty?: string,
        callback?: Function
    ) {
        if (values) {
            values.forEach(value => {
                this.add(
                    new Item(value, subValuesProperty, undefined, callback)
                );
            });
        }
    }

    /**
     * Returns true when any item is visible
     */

    get hasVisible(): boolean {
        return this.visible.length > 0;
    }

    /**
     * Returns true when any item is hidden
     */
    get hasHidden(): boolean {
        return this.hidden.length > 0;
    }

    /**
     * @deprecated
     * Create a new items object and add groups values as item.
     *
     * @param value
     *      List of values.
     *
     *  @Param subValuesProperty
     *      Name of a property that holds a list of values that should be used for sub items.
     *
     * @return
     *      List of items (objects).
     */

    static create(
        values: Array<any>,
        subValuesProperty?: string,
        callback?: Function
    ): Items {
        const items = new Items();

        values.forEach(function(value) {
            items.add(new Item(value, subValuesProperty, undefined, callback));
        });

        return items;
    }

    /**
     * Returns all selected items.
     *
     * @param withParentItems
     *  When true selected parent items are included.
     *
     * @param withSubItems
     *  When true selected sub items are included.
     *
     * @param consolidateParent
     *  When true,
     *      the parent item is included when all or non sub items are selected
     *      otherwise only the sub items are includes
     *
     * @returns
     *  List of groups selected items.
     */
    selected(
        selected: boolean,
        withParentItems = true,
        withSubItems = true,
        consolidateParent = false
    ): Array<Item> {
        if (
            withParentItems === false ||
            withSubItems === false ||
            consolidateParent === true
        ) {
            return findSelectedItems(
                this.all,
                selected,
                withParentItems,
                withSubItems,
                undefined,
                consolidateParent
            );
        } else {
            return this._selected;
        }
    }

    /**
     * Returns true when any item is selected.
     */
    get hasSelected(): boolean {
        return this._selected.length > 0;
    }

    /**
     * Returns true when all item is selected.
     */
    get hasAllItemsSelected(): boolean {
        return this._selected.length === this.all.length;
    }

    get allValues(): Array<any> {
        return this.all.map(item => item.value);
    }

    /**
     * Get the values of groups selected items.
     *
     * @param withParentItems
     * When true the values of selected parent items are included.
     *
     * @param withSubItems
     * When true the values of selected sub items are included.
     *
     * @returns
     * The values of groups selected items.
     */
    selectedValues(
        selected: boolean,
        withParentItems = true,
        withSubItems = true,
        consolidateParent = false
    ): Array<any> {
        const items = findSelectedItems(
            this.all,
            selected,
            withParentItems,
            withSubItems,
            undefined,
            consolidateParent
        );

        const values = items.map(function(item) {
            return item.value;
        });

        return values;
    }

    /**
     * Returns the value of the first selected item, or null if no item is selected.
     *
     * @param withParentItems
     *  When true the value of an selected parent items is included.
     *
     * @param withSubItems
     *  When true the value of an selected sub items is included.
     *
     * @returns
     */
    selectedValue(
        selected: boolean,
        withParentItems = true,
        withSubItems = true,
        consolidateParent = false
    ): any {
        const selectedValues = this.selectedValues(
            selected,
            withParentItems,
            withSubItems,
            consolidateParent
        );
        return selectedValues.length > 0 ? selectedValues[0] : null;
    }

    selectAll() {
        this.all.forEach(item => item.select());
    }

    selectBy(value: any, property?: string): Item | null {
        const item = this.findFirstBy(value, property);
        if (item) {
            item.select(true);
            return item;
        } else {
            return null;
        }
    }

    selectAllBy(values: Array<any>, property?: string): Array<Item> {
        const self = this;
        const allSelected: Array<Item> = [];

        values.forEach(value => {
            const selected = self.selectBy(value, property);
            if (selected) {
                allSelected.push(selected);
            }
        });

        return allSelected;
    }

    unselectAll() {
        this.all.forEach(item => item.select(false));
    }

    unselectBy(value: any, property?: string): Item | null {
        const item = this.findFirstBy(value, property);
        if (item) {
            item.select(false);
            return item;
        } else {
            return null;
        }
    }

    unselectAllBy(values: Array<any>, property?: string): Array<Item> {
        const self = this;
        const allUnselected: Array<Item> = [];

        values.forEach(value => {
            const unselected = self.unselectBy(value, property);
            if (unselected) {
                allUnselected.push(unselected);
            }
        });

        return allUnselected;
    }

    enableAll() {
        this.all.forEach(item => (item.disabled = false));
    }

    enableBy(value: any, property?: string): Item | null {
        const item = this.findFirstBy(value, property);
        if (item) {
            item.disabled = false;
            return item;
        } else {
            return null;
        }
    }

    enableAllBy(values: Array<any>, property?: string): Array<Item> {
        const self = this;
        const allSelected: Array<Item> = [];

        values.forEach(value => {
            const selected = self.enableBy(value, property);
            if (selected) {
                allSelected.push(selected);
            }
        });

        return allSelected;
    }

    disableAll() {
        this.all.forEach(item => (item.disabled = true));
    }

    disableBy(value: any, property?: string): Item | null {
        const item = this.findFirstBy(value, property);
        if (item) {
            item.disabled = true;
            return item;
        } else {
            return null;
        }
    }

    disableAllBy(values: Array<any>, property?: string): Array<Item> {
        const self = this;
        const allDisabled: Array<Item> = [];

        values.forEach(value => {
            const unselected = self.disableBy(value, property);
            if (unselected) {
                allDisabled.push(unselected);
            }
        });

        return allDisabled;
    }

    hideAll() {
        this.all.forEach(item => (item.hidden = true));
    }

    hideBy(value: any, property?: string): Item | null {
        const item = this.findFirstBy(value, property);
        if (item) {
            item.hidden = true;
            return item;
        } else {
            return null;
        }
    }

    hideAllBy(values: Array<any>, property?: string): Array<Item> {
        const self = this;
        const allHidden: Array<Item> = [];

        values.forEach(value => {
            const hidden = self.hideBy(value, property);
            if (hidden) {
                allHidden.push(hidden);
            }
        });

        return allHidden;
    }

    showAll() {
        this.all.forEach(item => (item.hidden = false));
    }

    showBy(value: any, property?: string): Item | null {
        const item = this.findFirstBy(value, property);
        if (item) {
            item.hidden = false;
            return item;
        } else {
            return null;
        }
    }

    showAllBy(values: Array<any>, property?: string): Array<Item> {
        const self = this;
        const allShown: Array<Item> = [];

        values.forEach(value => {
            const shown = self.showBy(value, property);
            if (shown) {
                allShown.push(shown);
            }
        });

        return allShown;
    }

    /**
     * Find a item by a property and/or value combination, or by item id.
     *
     * @param value
     * The value of the property or an object containing the property value.
     *
     * @param property
     * The property field.
     *
     * @returns
     * Array of groups items.
     */
    findBy(value: any, property?: string): Array<Item> {
        const items: Array<Item> = [];

        if (value && typeof value === "object" && property !== undefined) {
            value = value[property];
        }

        this.all.forEach(function(item) {
            if (property == null) {
                if (item.id === value || item.value === value) {
                    items.push(item);
                }
            } else {
                if (item.value[property] === value) {
                    items.push(item);
                }
            }

            item.subItems.forEach(function(subItem) {
                if (property == null) {
                    if (subItem.id === value || subItem.value === value) {
                        items.push(subItem);
                    }
                } else {
                    if (subItem.value[property] === value) {
                        items.push(subItem);
                    }
                }
            });
        });

        return items;
    }

    findFirstBy(value: any, property?: string): Item | null {
        const items = this.findBy(value, property);
        return items.length > 0 ? items[0] : null;
    }

    /**
     * Find the index of an item.
     *
     * @param item
     * The item
     *
     * @returns
     * Index of the item or -1 if not present.
     */
    indexOf(item: Item): number {
        return indexOfItem(item, this.all);
    }

    /**
     * Check if an item is present.
     *
     * @returns
     * True if the item is present, false otherwise.
     */
    contains(item: Item): boolean {
        return indexOfItem(item, this.all) > 0;
    }

    /**
     * Add an item if not already present.
     *
     * @param item
     * The item to add.
     *
     * @return
     * The added item.
     */
    add(item: Item): Item {
        const _self = this;

        if (indexOfItem(item, _self.all) === -1) {
            _self.all.push(item);
            item.source = _self;

            if (item.hidden) {
                this.hidden = [...this.hidden, item];
            } else {
                this.visible = [...this.visible, item];
            }

            item.subItems.forEach(function(subItem) {
                subItem.source = _self;
            });
        }

        return item;
    }

    /**
     * Remove an item if present.
     *
     * @param item
     * The item to remove
     *
     * @returns
     * Returns the removed item
     */
    remove(item: Item): Item {
        const _self = this;

        if (item.source !== _self) {
            return;
        }

        item.selected = false;

        if (item.parent) {
            removeItem(item, item.parent.subItems);
        }

        removeItem(item, _self.all);

        item.source = undefined;
        return item;
    }

    /**
     * Remove all items.
     *
     * @param items
     * The items to remove.
     *
     * @returns
     * Returns the removed items.
     */
    removeAll(items: Array<Item>): Array<Item> {
        if (items) {
            items.forEach(item => this.remove(item));
        }

        return items;
    }

    /**
     * Removes any item that matches any value in values.
     *
     * @param values
     * The values used to find matching items.
     *
     * @param property
     * The property field.
     *
     * @returns
     * Array of removed items.
     */
    removeBy(values: any, property?: string): Array<Item> {
        let toRemove = [];

        if (values instanceof Array) {
            for (const value of values) {
                const items = this.findBy(value, property);
                toRemove.push(...items);
            }
        } else {
            toRemove = this.findBy(values, property);
        }

        toRemove.forEach(item => this.remove(item));
        return toRemove;
    }

    removeSelected(selected = true): Array<Item> {
        const toRemove = this.selected(selected);
        toRemove.forEach(item => this.remove(item));
        return toRemove;
    }

    /**
     * Resets all selected items.
     */
    reset() {
        this.all.forEach(function(item) {
            item.selected = false;
        });

        this._selected = [];
    }

    /**
     * Clear all items
     */
    clear() {
        this.all = [];
        this._selected = [];
    }

    /**
     * Returns true when no items are defined.
     */

    get isEmpty(): boolean {
        return this.all.length === 0;
    }

    get isNotEmpty(): boolean {
        return !this.isEmpty;
    }

    /**
     * Returns the first item.
     */

    get first(): Item | null {
        return this.isEmpty ? null : this.all[0];
    }

    /**
     * Returns the last item.
     */
    get last(): Item | null {
        return this.isEmpty ? null : this.all[this.all.length - 1];
    }

    merge(items: Array<Item>): void {
        items.forEach(item => {
            const selfItem =
                this.findFirstBy(item.id) || this.findFirstBy(item.value);

            if (selfItem) {
                selfItem.addAll(item.subItems);
            } else {
                this.add(item);
            }
        });
    }
}

/****************************************
 *   Private Functions
 ****************************************/

/**
 * Creates a new UUID.
 *
 * @returns
 */
function uuid() {
    let d = new Date().getTime();
    let uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(
        c
    ) {
        let r = (d + Math.random() * 16) % 16 | 0;
        d = Math.floor(d / 16);
        return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
    });

    return uuid;
}

function findSelectedItems(
    sourceList: Array<Item>,
    selected: boolean,
    withParentItems?: boolean,
    withSubItems?: boolean,
    targetList?: Array<Item>,
    consolidateParent = false
): Array<Item> {
    if (withParentItems == null) {
        withParentItems = true;
    }

    if (withSubItems == null) {
        withSubItems = true;
    }

    const items = targetList != null ? targetList : [];

    sourceList.forEach(function(item) {
        if (item.isParent) {
            if (
                item.selected === selected &&
                withParentItems &&
                (!consolidateParent ||
                    item.allSubItemsSelected(true) ||
                    item.allSubItemsSelected(false))
            ) {
                items.push(item);
            }

            if (
                withSubItems &&
                (!consolidateParent || !item.allSubItemsSelected())
            ) {
                findSelectedItems(
                    item.subItems,
                    selected,
                    withParentItems,
                    withSubItems,
                    items
                );
            }
        } else {
            if (withSubItems && item.selected === selected) {
                items.push(item);
            }
        }
    });

    return items;
}

function indexOfItem(item: Item, list: Array<Item>): number {
    const idx = list.findIndex(function(_item) {
        return _item.id === item.id;
    });

    return idx;
}

function removeItem(item: Item, list: Array<Item>): boolean {
    const idx = indexOfItem(item, list);
    if (idx >= 0) {
        list.splice(idx, 1);
        return true;
    } else {
        return false;
    }
}
