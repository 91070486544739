import {GLOBAL_STORE_CONFIG_OPTIONS} from '../store-options';

export function Epic() {
    return (target: any, key: string | symbol | number): void => {
        GLOBAL_STORE_CONFIG_OPTIONS.epics.push({
            target: target.constructor,
            method: key
        });
    };
}
