import { Directive, ElementRef, Input } from "@angular/core";

@Directive({
  selector: "[hideSelectIcon]"
})
export class HideSelectIconDirective {
  constructor(private elementRef: ElementRef) {}

  enabled: boolean = null;

  @Input()
  hideSelectIcon(enabled: boolean) {
    this.enabled = enabled;

    if (enabled !== null) {
      this.hideIcon(true);
    }
  }

  @Input()
  set disabled(disabled: boolean) {
    if (this.enabled == null) {
      this.hideIcon(disabled);
    }
  }

  private hideIcon(hide: boolean) {
    setTimeout(() => {
      const el = this.elementRef.nativeElement.shadowRoot.querySelector(
        ".select-icon-inner"
      );

      if (!el) {
        return;
      }

      if (hide) {
        el.setAttribute("style", "display: none !important");
      } else {
        el.setAttribute("style", null);
      }
    }, 500);
  }
}
