import {
    HttpEvent,
    HttpHandler,
    HttpHeaders,
    HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiConfig } from "../models/api-config.model";

export abstract class ApiInterceptor implements HttpInterceptor {
    constructor() {}

    abstract get config(): ApiConfig;

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        if (this.config == null) {
            return next.handle(request);
        }

        if (this.config.enabledForUrl(request.url)) {
            let headers = request.headers;
            if (this.config.headers) {
                this.config.headers.forEach(header => {
                    headers = headers.set(header.name, header.value);
                });
            }

            request = request.clone({
                url: this.mapApi(this.config, request.url),
                headers: this.addAuth(this.config, headers)
            });
        }

        return next.handle(request);
    }

    mapApi(config: ApiConfig, url: string) {
        if (!config.mappings) {
            return url;
        }

        for (const api of config.mappings) {
            if (url.indexOf(api.name) >= 0) {
                url = `/${config.baseUrl}/${url.replace(api.name, api.path)}`;
                url = url.replace(/\/\/+/g, "/");
                url = `${config.scheme}://${config.host}.${config.domain}:${config.port}${url}`;

                return url;
            }
        }

        return url;
    }

    addAuth(config: ApiConfig, headers: HttpHeaders) {
        if (!config.auth) {
            return headers;
        }

        if (config.auth.token && config.auth.token.value) {
            headers = headers.set(
                config.auth.token.header,
                config.auth.token.value
            );
        }

        if (config.auth.basic && config.auth.basic.username) {
            headers = headers.set(
                "Authorization",
                "Basic " +
                    btoa(
                        config.auth.basic.username +
                            ":" +
                            config.auth.basic.password
                    )
            );
        }

        return headers;
    }
}
