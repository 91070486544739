import {
    InjectionToken,
    Injector,
    ModuleWithProviders,
    NgModule
} from "@angular/core";
import {
    DevToolsExtension,
    NgRedux,
    NgReduxModule
} from "@angular-redux/store";
import { createEpicMiddleware } from "redux-observable";
import { createLogger } from "redux-logger";
import { reduceReducers } from "./utils";
import { GLOBAL_STORE_CONFIG_OPTIONS, StoreOptions } from "./store-options";

export const STORE_CONFIG_OPTIONS = new InjectionToken<StoreOptions>(
    "STORE_CONFIG_OPTIONS"
);

@NgModule({
    imports: [NgReduxModule]
})
export class StoreConfigModule {
    static forRoot(options: Partial<StoreOptions>): ModuleWithProviders {
        return {
            ngModule: StoreConfigModule,
            providers: [
                {
                    provide: STORE_CONFIG_OPTIONS,
                    useValue: options ? options : {}
                }
            ]
        };
    }

    constructor(
        store: NgRedux<any>,
        injector: Injector,
        devTools: DevToolsExtension
    ) {
        const storeOptions = injector.get(STORE_CONFIG_OPTIONS);
        storeOptions.withLogger = storeOptions.withLogger || false;

        storeOptions.middleware = [
            ...(storeOptions.middleware || []),
            ...GLOBAL_STORE_CONFIG_OPTIONS.middleware
        ];

        storeOptions.enhancers = [
            ...(storeOptions.enhancers || []),
            ...GLOBAL_STORE_CONFIG_OPTIONS.enhancers
        ];

        storeOptions.epics = [
            ...(storeOptions.epics || []),
            ...GLOBAL_STORE_CONFIG_OPTIONS.epics
        ];

        storeOptions.reducers = [
            ...(storeOptions.reducers || []),
            ...GLOBAL_STORE_CONFIG_OPTIONS.reducers
        ];

        storeOptions.autoRun = [
            ...(storeOptions.autoRun || []),
            ...GLOBAL_STORE_CONFIG_OPTIONS.autoRun
        ];

        // Configure reducers
        const reducers = [];

        if (storeOptions.rootReducer) {
            reducers.push(storeOptions.rootReducer);
        }

        for (const reducerDescriptor of storeOptions.reducers) {
            if (reducerDescriptor.class) {
                let provider;
                try {
                    provider = injector.get(reducerDescriptor.target);
                } catch (error) {
                    provider = reducerDescriptor.class;
                }

                const method = provider[reducerDescriptor.method];
                reducers.push(method);
            } else {
                reducers.push(reducerDescriptor.method);
            }
        }

        const rootReducer = reduceReducers(...reducers);

        // Configure epics
        const epics = [];

        for (const epicDescriptor of storeOptions.epics) {
            const provider = injector.get(epicDescriptor.target);
            const method = provider[epicDescriptor.method];
            const epic = createEpicMiddleware(method);
            epics.push(epic);
        }

        // Configure enhancers
        const enhancers = [...storeOptions.enhancers];

        if (devTools.isEnabled()) {
            enhancers.push(devTools.enhancer());
        }

        // Configure middleware
        const middleware = [...storeOptions.middleware, ...epics];
        if (storeOptions.withLogger) {
            middleware.push(createLogger());
        }

        store.configureStore(
            rootReducer,
            storeOptions.initState,
            middleware,
            enhancers
        );
    }
}
