import { Entity } from "./entity.model";

export class DataField extends Entity {
    refId: string;
    name: String;
    label: string;
    value: any;

    constructor(field?: Partial<DataField>) {
        super(field);

        if (field) {
            Object.assign(this, field);
        }
    }
}
