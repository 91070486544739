import { Document } from "./document.model";
import { isEmpty } from "@tcn/ngx-common";

export class Image extends Document {
    constructor(image?: Partial<Document | Image>) {
        super(image);

        if (image) {
            Object.assign(this, image);
        }

        // Default values.
        this.meta.tags = this.meta.tags || [];
        this.meta.sizes = this.meta.sizes || {};

        if (!("thumb" in this.meta.sizes)) {
            this.meta.sizes.thumb = null;
        }

        if (!("small" in this.meta.sizes)) {
            this.meta.sizes.small = null;
        }

        if (!("medium" in this.meta.sizes)) {
            this.meta.sizes.medium = null;
        }

        if (!("large" in this.meta.sizes)) {
            this.meta.sizes.large = null;
        }

        if (!("original" in this.meta.sizes)) {
            this.meta.sizes.large = null;
        }
    }

    get tags(): string[] {
        return this.meta.tags;
    }

    set tags(tags: string[]) {
        this.meta.tags = tags;
    }

    get thumb(): Image {
        const src = this.meta.sizes.thumb || this.src;
        const thumb = new Image({
            ...(this as object),
            src: src
        });

        return thumb;
    }

    get small(): Image {
        const src = this.meta.sizes.small || this.src;
        const small = new Image({
            ...(this as object),
            src: src
        });

        return small;
    }

    get medium(): Image {
        const src = this.meta.sizes.medium || this.src;
        const medium = new Image({
            ...(this as object),
            src: src
        });

        return medium;
    }

    get large(): Image {
        const src = this.meta.sizes.large || this.src;
        const large = new Image({
            ...(this as object),
            src: src
        });

        return large;
    }

    get original(): Image {
        const src = this.meta.sizes.original || this.src;
        const original = new Image({
            ...(this as object),
            src: src
        });

        return original;
    }

    get sizes(): Image[] {
        const sizes = Object.keys(this.meta.sizes).map(
            key =>
                new Image({
                    ...(this as object),
                    src: this.meta.sizes[key]
                })
        );

        return sizes.filter(img => !isEmpty(img.src));
    }
}
