import { Entity } from "../../core/models/entity.model";

export class Document extends Entity {
    createdOn: Date;
    updatedOn: Date;

    refId: string;
    meta: any = {};
    author: string;
    name: string;

    path: string;
    directory: string;
    filename: string;

    description: string;
    category: string;
    mimeType: string;
    content: any;
    src: string;

    constructor(doc?: Partial<Document>) {
        super(doc);

        if (doc) {
            Object.assign(this, doc);

            this.createdOn = doc.createdOn ? new Date(doc.createdOn) : null;
            this.updatedOn = doc.updatedOn ? new Date(doc.updatedOn) : null;

            if (doc.meta) {
                this.meta = JSON.parse(JSON.stringify(doc.meta));
            }
        }

        // Default values
        this.meta = this.meta || {};
        this.createdOn = this.createdOn || new Date();
    }
}
