import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { QueryResponse } from "../../core/models/query-response.model";
import { Query } from "../../core/models/query.model";
import { Voucher } from "../models/voucher.model";
import { removeUnderscoreKeys } from "../../utils/utils-fns";

export class VoucherQuery extends Query {
    identity: string;
    type: string;
    search: string;
    number: string;
    branch: string;
    customer: string;
    storage: string;
    vehicle: string;
    createdAfter: Date;
    createdBefore: Date;

    constructor(query?: Partial<VoucherQuery>) {
        super(query);

        if (query) {
            Object.assign(this, query);
        }

        this.sortBy = this.sortBy || "creationDate";
        this.sortType = this.sortType || "desc";
    }

    get empty(): boolean {
        const empty =
            !this.type &&
            !this.number &&
            !this.branch &&
            !this.customer &&
            !this.storage &&
            !this.vehicle &&
            !this.createdAfter &&
            !this.createdBefore;

        return empty;
    }
}

@Injectable({ providedIn: "root" })
export class VoucherService {
    constructor(protected http: HttpClient) {}

    queryVouchers(query: VoucherQuery): Observable<QueryResponse<Voucher>> {
        return this.http.post("neonis.api.vouchers.v1/query", query).pipe(
            map(
                (response: any) =>
                    new QueryResponse<Voucher>({
                        ...response,
                        data: response.data.map(
                            (voucher) => new Voucher(voucher)
                        ),
                    })
            )
        );
    }

    getVoucher(voucherId: string): Observable<Voucher> {
        return this.http
            .get(`neonis.api.vouchers.v1/${voucherId}`)
            .pipe(map((voucher) => new Voucher(voucher)));
    }

    saveVoucher(voucher: Voucher): Observable<Voucher> {
        const request = removeUnderscoreKeys(
            JSON.parse(JSON.stringify(voucher))
        );

        const save$ = this.http.post(`neonis.api.vouchers.v1`, request);
        return save$.pipe(map((response) => new Voucher(response)));
    }

    deleteVoucher(voucherId: string): Observable<any> {
        return this.http.delete(`neonis.api.vouchers.v1/${voucherId}`);
    }
}
