import { join } from "@tcn/ngx-common";

export class TyreInfo {
    createdAt: Date;
    updatedAt: Date;

    id: string;
    manufacturer: string;
    manufacturerId: string;
    ean: string;
    group: string;
    type: string;
    description: string;
    width: string;
    crossSection: string;
    diameter: string;
    construction: string;
    loadIdx1: string;
    speedIdx1: string;
    loadIdx2: string;
    speedIdx2: string;
    loadIdx3: string;
    speedIdx3: string;
    loadIdx4: string;
    speedIdx4: string;
    conducting: string;
    profile: string;
    reinforced: string;
    plyRate: string;
    emtLoadCapacity: string;
    ovlDiameter: string;
    sizePrefix: string;
    oeTag: string;
    runflat: string;
    flank: string;
    mps: string;
    sidewall: string;
    weight: number;
    noiseDb: number;
    noiseClass: string;
    rollingFriction: string;
    wetGrip: string;
    tyreClass: string;
    salesPrice: number;

    constructor(tyre?: Partial<TyreInfo>) {
        if (tyre) {
            Object.assign(this, tyre);

            this.createdAt = tyre.createdAt ? new Date(tyre.createdAt) : null;
            this.updatedAt = tyre.updatedAt ? new Date(tyre.updatedAt) : null;
        }
    }

    get dimensionLabel(): string {
        return join([
            [this.width, "?:/", this.crossSection],
            [this.construction, this.diameter],
            [this.loadIdx1, this.speedIdx1]
        ]);
    }

    get profileLabel(): string {
        return join([this.profile, this.reinforced, this.flank, this.mps]);
    }

    get label(): string {
        return join([
            this.dimensionLabel,
            this.manufacturer,
            this.profileLabel
        ]);
    }
}
