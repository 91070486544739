export function isEmpty(value: any): boolean {
    if (value == null) {
        return true;
    }

    if (typeof value === "string") {
        return value.trim().length === 0;
    } else if (Array.isArray(value)) {
        return value.length === 0;
    }

    return false;
}

export function trim(value: string, defaultValue = null) {
    if (value === null) {
        return defaultValue;
    }

    return value.trim() || defaultValue;
}

export function equals(left: any, right: any) {
    if (typeof left === "string" || typeof right === "string") {
        if (left != null) {
            left = typeof left === "string" ? trim(left) : left.toString();
        }

        if (right != null) {
            right = typeof right === "string" ? trim(right) : right.toString();
        }
    }

    return left == right;
}

export function orElse(value: any, defaultValue: any) {
    return isEmpty(value) ? defaultValue : value;
}

export function sortByProperty(arr: Array<any>, fields: string[]): Array<any> {
    const fieldSorter = () => (a, b) =>
        fields
            .map((o) => {
                let dir = 1;
                if (o.endsWith(":desc")) {
                    dir = -1;
                    o = o.substring(1);
                }
                return a[o] > b[o] ? dir : a[o] < b[o] ? -dir : 0;
            })
            .reduce((p, n) => (p ? p : n), 0);

    arr.sort(fieldSorter());
    return arr;
}

export function flatten(arr: Array<any>): Array<any> {
    return [].concat.apply([], arr);
}

export function grid(cols: number, data: Array<any>): Array<Array<any>> {
    const grid = [];
    let colIdx = 0;
    let row = [];

    for (const item of data) {
        if (colIdx % cols === 0) {
            row = [];
            grid.push(row);
        }

        row.push(item);
        colIdx += 1;
    }

    return grid;
}

export function unique(arr: Array<any>[], property?: string) {
    if (isEmpty(property)) {
        return [...new Set(arr)];
    } else {
        const result = [];
        const map = new Map();

        for (const item of arr) {
            if (!map.has(item[property])) {
                map.set(item[property], true);
                result.push(item);
            }
        }

        return result;
    }
}

export interface StringPart {
    value: boolean | number | string;
    prefix?: string;
    postfix?: string;
    trim?: boolean;
}

export function join(
    values: Array<
        | boolean
        | number
        | string
        | StringPart
        | Array<boolean | number | string | StringPart>
    >,
    separator = " ",
    mergeWhitespaces = true
): string {
    let lastPart = null;
    let nextPrefix = null;

    const parts: any[] = values
        .map((value: any) => {
            if (value == null) {
                return null;
            }

            let part = null;

            if (typeof value === "string") {
                if (value.startsWith("?:")) {
                    if (lastPart) {
                        lastPart.postfix = value.substr(2);
                    }

                    return null;
                } else if (value.endsWith(":?")) {
                    nextPrefix = value.substr(0, -2);
                    return null;
                } else {
                    part = {
                        value: value,
                        trim: true,
                    };
                }
            } else if (Array.isArray(value)) {
                part = {
                    value: join(value, ""),
                    trim: true,
                };
            } else {
                part = {
                    value: value.toString(),
                    trim: false,
                };
            }

            if (nextPrefix != null) {
                part.prefix = nextPrefix;
                nextPrefix = null;
            }

            lastPart = part;
            return part;
        })
        .filter((part) => part != null);

    let joined = "";

    for (let i = 0; i < parts.length; ++i) {
        const part = parts[i];

        if (part.value != null) {
            if (part.prefix) {
                joined += part.prefix;
            }

            joined +=
                (part.trim ? part.value.trim() : part.value) +
                (i < parts.length - 1 ? separator : "");

            if (part.postfix) {
                joined += part.postfix;
            }
        }
    }

    if (mergeWhitespaces) {
        joined = joined.replace(/ +/, " ").trim();
    }

    return joined;
}
