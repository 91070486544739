import { LocaleConfigService } from "../../services/locale-config.service";
import { formatNumber } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "localeNumber"
})
export class LocaleNumberPipe implements PipeTransform {
    constructor(protected localeConfig: LocaleConfigService) {}

    transform(value: any, format = ".2-2") {
        if (value == null) {
            return "";
        }

        if (!format) {
            format = ".2-2";
        }

        const lang = this.localeConfig.getActiveLanguage();
        return formatNumber(value, lang, format);
    }
}
