import { formatISO, getDate, getMonth, parseISO } from "date-fns";
import { Address } from "../../core/models/address.model";
import { ContactInfo } from "../../core/models/contact-info.model";
import { Entity } from "../../core/models/entity.model";

export class Driver extends Entity {
    title: string;
    name: string;
    firstName: string;
    lastName: string;
    contactInfo: ContactInfo;
    address: Address;
    birthday: Date;

    constructor(driver?: Partial<Driver>) {
        super(driver);

        if (driver) {
            Object.assign(this, driver);

            this.address = new Address(driver.address);
            this.contactInfo = new ContactInfo(driver.contactInfo);
        }

        // Default values.
        this.address = this.address || new Address();
        this.contactInfo = this.contactInfo || new ContactInfo();
    }

    get birthdayISO(): string {
        return this.birthday ? formatISO(this.birthday) : null;
    }

    set birthdayISO(date: string) {
        this.birthday = parseISO(date);
    }

    get hasBirthday(): boolean {
        const currentDate = new Date();

        return (
            this.birthday != null &&
            getMonth(currentDate) === getMonth(this.birthday) &&
            getDate(currentDate) === getDate(this.birthday)
        );
    }
}
